import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ButtonFieldInput from '../../common/uiComponents/commonTextField/buttonField/Button';
import radiobtn from "../../Images/Icons/radiobtn.png";
import whitecircle from "../../Images/Icons/whitecircle.png";
import { isExpired } from '../../utils/commonFunc';

const QuoteDetailsWithOffer = (props: any) => {
    const [offerTaken, setOfferTaken] = useState(false);
    const userDetails = useSelector((state: any) => state?.userReducer?.userDetails);
    const quoteData = useSelector((state: any) => state.diagramReducer?.quoteData);
    const salesOfferData = useSelector((state: any) => state.invoiceReducer?.salesOfferDetails);
    const invoiceList = useSelector((state: any) => state?.invoiceReducer?.invoiceDetails)
    const taxValue = useSelector((state: any) => state?.invoiceReducer?.taxValue)
    const checkingConditionForSales =  userDetails && !userDetails?.hasOwnProperty('coupon') && !isExpired(userDetails?.coupon?.expiry) && salesOfferData && salesOfferData?.length > 0;
    const checkingConditionForInvoice = invoiceList && Object.keys(invoiceList).length > 0;

    const handleOffer = () => {
        setOfferTaken(!offerTaken);
    }


    const calculatePercentage = (sum: number, percentage: number): number => {
        return (percentage / 100) * sum;
    };

    // Function to strip non-numeric characters and parse as float
    const parsePrice =(price: any) => {
        return parseFloat(price.toString().replace(/[^0-9.]/g, ''));
    }

    const subTotal = () => {
        let totalPrice: number = 0;
        let sum: number = 0
        let price: number = 0;
        if (quoteData?.items?.length > 0) {
            quoteData?.items?.map((item: any) => {
                let netPriceCal: any = Number(item?.area) * (Number(item?.net?.price)) * item?.quantity || 0;
                let supportCal = item?.support?.reduce((a: any, c: any) => a + (Number(c.id.price) * Number(c?.quantity)), 0)
                let total: number = (Number(item?.chain) * 29.99) + netPriceCal + supportCal
                sum += total
            })
            // return sum.toFixed(2);
        }
        if (quoteData?.addOns?.length > 0) {
            quoteData?.addOns?.map((addon: any) => {
                price += (Number(addon?.item?.price) * (addon?.quantity))
            })
            // return price.toFixed(2);
        }
        totalPrice = sum + price;
        return (Number(totalPrice));
    }
    const subTotalPrice = subTotal()

    const discountWhenPercent = () => {
        const totalPrice = subTotal();
        const percentage = checkingConditionForSales && salesOfferData[0]?.percentage;
        const uptoPrice = checkingConditionForSales && salesOfferData[0]?.upto;
        const percentagePrice = calculatePercentage(totalPrice, percentage);
        if(percentagePrice > uptoPrice){
            return (uptoPrice).toFixed(2);
        }
        return (percentagePrice).toFixed(2);
    }

    const discountPrice = offerTaken ? (checkingConditionForSales && (salesOfferData[0]?.type === 'flat' ? (salesOfferData[0]?.amount).toFixed(2) : salesOfferData[0]?.type === 'percentage' ? discountWhenPercent() : null))  : '0.00'

    // call get quote api
    useEffect(() => {
        props.getInvoiceDetails()
        props.getQuotes()
        props.getSaleDetails()
    }, []);

    return (
        <div className={`w-full flex items-start justify-between gap-4 my-12`}>
            {/** Details Section */}
            <div className={`px-4 w-[70%] h-full`}>
                <table className={`table-auto w-fit border-[#E7E7E7] border-[1px] !rounded-md`}>
                    <thead className={`bg-[#fff] h-12 border-[#E7E7E7] border-b-[1px]`}>
                        <tr>
                            <th className={`w-64 text-left pl-8 text-base font-bold font-[SansRegular]`}>Products</th>
                            <th className={`w-40 text-center text-base font-bold font-[SansRegular]`}>Qnty/Size</th>
                            <th className={`w-40 text-left text-base font-bold font-[SansRegular]`}>Units</th>
                            <th className={`w-40 text-right pr-8 text-base font-bold font-[SansRegular]`}>Units Price (USD)</th>
                            <th className={`w-40 text-right pr-8 text-base font-bold font-[SansRegular]`}>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quoteData?.items?.length > 0 ? quoteData?.items?.map((item: any, idx: any) => {
                            return (
                                <>
                                    <tr key={idx} className={`border-b-[#E7E7E7] border-b border-solid`}>
                                        <td className={`w-64 text-left py-3 pl-8 text-base font-medium font-[SansRegular] truncate`}>
                                            <Tooltip title={`${item?.itemName}(${item?.net?.name})(${item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : null})`} placement="top" arrow>
                                                <p className={`truncate w-[224px]`}>{item?.itemName} <span className={`text-sm`}>({item?.net?.name})({item?.netColor === "#FFFFFF" || item?.netColor === "#ffe6e6" ? "White" : item?.netColor === "#D9D9D9" || item?.netColor === "#867979" ? "Black" : null})</span></p>
                                            </Tooltip>
                                        </td>
                                        <td className={`w-40 text-center text-base font-medium font-[SansRegular]`}>{item?.quantity}</td>
                                        <td className={`w-40 text-left text-base font-medium font-[SansRegular]`}>{Math.round(Number(item?.area))} Sq. ft</td>
                                        <td className={`w-40 text-right pr-8 text-base font-medium font-[SansRegular]`}>${(Number(item?.net?.price)).toFixed(2)}</td>
                                        <td className={`w-40 text-right pr-8 text-base font-medium font-[SansRegular]`}>${(Number(item?.area) * (Number(item?.net?.price)) * item?.quantity).toFixed(2)}</td>
                                    </tr>
                                    {
                                        item?.chain > 0 &&
                                        <tr key={idx} className={`border-b-[#E7E7E7] border-b border-solid`}>
                                            <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>Tensioner Chain+3 Carabiners</td>
                                            <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{item?.chain}</td>
                                            <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{item?.chain > 1 ? 'Sets' : 'Set'}</td>
                                            <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>$29.99</td>
                                            <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${(Number(item?.chain) * 29.99).toFixed(2)}</td>
                                        </tr>
                                    }
                                    {item?.support?.length > 0 && item?.support?.map((support: any, idx: number) => {
                                        return (
                                            support?.quantity !== null && support?.quantity !== 0 && <tr key={idx} className={`border-b-[#cacaca] border-b border-solid`}>
                                                <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>{support?.id?.name} {!support?.id?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}</td>
                                                <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{support?.quantity}</td>
                                                <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{support?.attachedWith ? (support?.quantity > 1 ? 'Pcs.' : 'Pc.') : (support?.quantity > 1 ? 'Pcs.' : 'Pc.')}</td>
                                                <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${support?.id?.price}</td>
                                                <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${(support?.id?.price * (support?.quantity)).toFixed(2)}</td>
                                            </tr>
                                        )
                                    })}
                                </>
                            )
                        }) : null}

                        {
                            quoteData?.addOns?.length > 0 ? quoteData?.addOns?.filter((itm: any) => itm?.quantity !== 0 && itm?.quantity !== null).map((addon: any, idx: number) => {
                                return (
                                    <tr key={idx} className={`border-b-[#cacaca] border-b border-solid`}>
                                        <td className={`w-64 text-left py-3 pl-8 text-base font-normal font-[SansRegular]`}>{addon?.item?.name}{!addon?.item?.taxable && <span className={`text-blue-500 text-[20px]`}>*</span>}</td>
                                        <td className={`w-40 text-center text-base font-normal font-[SansRegular]`}>{addon?.quantity}</td>
                                        <td className={`w-40 text-left text-base font-normal font-[SansRegular]`}>{addon?.attachedWith ? (addon?.quantity > 1 ? 'Pcs.' : 'Pc.') : (addon?.quantity > 1 ? 'Pcs.' : 'Pc.')}</td>
                                        <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${addon?.item?.price}</td>
                                        <td className={`w-40 text-right pr-8 text-base font-normal font-[SansRegular]`}>${(addon?.item?.price * (addon?.quantity)).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                                : null
                        }
                    </tbody>
                </table>
            </div>

            {/** Offers + Pricing Section */}
            <div className={`w-[30%] flex flex-col gap-6 mr-4`}>
                {/** Offer */}
                {/* {checkingConditionForSales ? */}
                    {salesOfferData && salesOfferData?.length !== 0 &&  <div className={`border-[#E7E7E7] border-[1px] rounded-[10px] p-4 w-full flex flex-col gap-3`}>
                        <p className={`text-[#4A4A4A] text-lg font-[InterBold]`}>{`Offers`}</p>
                        {!offerTaken ?
                            <div className={`bg-[#F8F8F8] rounded-[11px] p-4 flex gap-3 w-full cursor-pointer`} onClick={handleOffer}>
                                <p className={`pt-1 cursor-pointer`} onClick={handleOffer}>
                                    <img src={whitecircle} alt="img" className={`h-5`} />
                                </p>
                                <div className={`flex flex-col items-start`}>
                                    <p className={`text-[#4A4A4A] text-[19px] font-[InterBold]`}>{((salesOfferData[0]?.type === 'flat' ? `Flat $ ${(salesOfferData[0]?.amount).toFixed(2)}` : salesOfferData[0]?.type === 'percentage' ? `${salesOfferData[0]?.percentage} %` : null))} off</p>
                                    <p className={`text-[#4A4A4A] text-[15px] font-[InterMedium]`}>{salesOfferData[0]?.name}</p>
                                </div>
                            </div>
                            :
                            <div className={`bg-[#EAF5FF] border-[#008CFF] flex gap-3 items-start border-[1px] p-4 rounded-[11px]`}>
                                <p className={`pt-1 cursor-pointer`} onClick={handleOffer}>
                                    <img src={radiobtn} alt="img" className={`h-5 bg-[#EAF5FF]`} />
                                </p>
                                <div className={`flex flex-col w-full`}>
                                    <div className={`flex items-center justify-between w-full`}>
                                        <p className={`text-[#4A4A4A] text-[19px] font-[InterBold]`}>{checkingConditionForSales && salesOfferData[0]?.name}</p>
                                        {/* <p className={`text-[#219653] text-[17px] font-[InterSemiBold]`}>{'-$' + (discountPrice)}</p> */}
                                    </div>
                                    <div className={`flex items-center justify-between w-full`}>
                                        <p className={`text-[#007E7D] text-[13px] font-[InterMedium]`}>{`APPLIED`}</p>
                                        <p className={`text-[#008CFF] text-[14px] font-[InterSemiBold] cursor-pointer`} onClick={handleOffer}>{`REMOVE`}</p>
                                    </div>
                                </div>
                            </div>}
                    </div>}
                    {/* :
                    <div className={`border-[#E7E7E7] border-[1px] rounded-[10px] p-4 w-full flex flex-col gap-3`}>
                        {userDetails && userDetails?.hasOwnProperty('coupon') ? <p className={`text-[#4A4A4A] text-lg font-[InterBold]`}>{`You already have ${userDetails?.coupon?.discount}% off on checkout`}</p> : <p className={`text-[#4A4A4A] text-lg font-[InterBold]`}>{`No Offers`}</p>}
                    </div>} */}

                {/** Pricing */}
                {/* <div className={`border-[#E7E7E7] border-[1px] rounded-[10px] p-4 w-full flex justify-between items-start`}>
                    <div className={`flex flex-col gap-4 items-start justify-start`}>
                        <p className={`text-[#4A4A4A] text-[17px] font-[InterSemiBold]`}>Sub-Total (USD)</p>
                        {checkingConditionForSales && <p className={`text-[#4A4A4A] text-[17px] font-[InterSemiBold]`}>Discount</p>}
                        {(!invoiceList?.willCall && invoiceList?.shippingAddress?.country === 'US') && <p className={`text-[#4A4A4A] text-[17px] font-[InterSemiBold]`}>Shipping & Handling</p>}
                        <p className={`text-[#4A4A4A] text-[17px] font-[InterSemiBold]`}>Tax</p>
                        <p className={`text-[#4A4A4A] text-[17px] font-[InterBold]`}>Grand Total(USD)*</p>
                    </div>
                    <div className={`flex flex-col gap-4 items-start justify-start`}>
                        <p className={`text-[#5A5A5A] text-[17px] font-[InterMedium]`}>${subTotalPrice.toFixed(2)}</p>
                        {checkingConditionForSales && <p className={`text-[#219653] text-[17px] font-[InterMedium]`}>{`- $ ${(discountPrice)}`}</p>}
                        {(!invoiceList?.willCall && invoiceList?.shippingAddress?.country === 'US') && <p className={`text-[#5A5A5A] text-[17px] font-[InterMedium]`}>${shippingPrice}</p>}
                        <p className={`text-[#5A5A5A] text-[17px] font-[InterMedium]`}>${taxPrice}</p>
                        <p className={`text-[#219653] text-[17px] font-[InterBold]`}>${(grandTotalPrice).toFixed(2)}</p>
                    </div>
                </div> */}
                <ButtonFieldInput
                    name="CONTINUE"
                    type="submit"
                    onClick={()=>{
                        if(invoiceList?.shippingAddress){
                           props?.updateOrder(invoiceList?.shippingAddress, offerTaken, salesOfferData && salesOfferData?.length !==0 ?  salesOfferData[0]?._id : null, () => props.continueToInvoice(discountPrice, checkingConditionForSales && salesOfferData[0]?._id));
                        }

                    }}
                    buttonextracls={`!text-white !text-lg !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl !font-[InterBold] font-[InterBold] cursor-pointer`}
                    loading={props?.isUpdateLoading}
                    loadingText={`Generating Invoice`}
                />
                <div className={`border-t-2 border-t-[#BDBDBD] border-solid py-4 mx-8`}>
                  <h2 className={`text-sm italic font-normal font-[SansRegular]`}>*Note:</h2>
                  <ul className='list-disc ml-5 text-[18px]'>
                  <li className={`text-sm font-normal font-[SansRegular]`}><span className={`text-[20px] text-blue-500`}>* </span>{` indicates non-taxable items`}</li>
                  </ul>
            </div>
            </div>
            
        </div>
    )
}

export default QuoteDetailsWithOffer