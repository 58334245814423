import { useCallback, useEffect, useMemo, useState } from "react";
import { fabric } from "fabric";
import { useFabricJSEditor } from 'fabricjs-react'
import wbg from "../../Images/whiteBackground.jpg";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { updateQuote, uploadFileData } from "../../redux/action";
import { doDeleteApiCall, doGetApiCall, doPostApiCall, doPutApiCall } from "../../utils/ApiConfig";
import { getDiagramSuccess, getQuoteData } from "../quote/quoteReducer/diagramReducer";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { setNotificationOpen } from "../notificationReducer/NotificationReducer";
import intersectFun from "../../utils/intersect";
import 'fabric-history';
import { useFormik } from 'formik';
import * as Yup from "yup";
import panel1 from '../../Images/sidenav/panel1.svg'
import panel2 from '../../Images/sidenav/panel2.svg'
import getLocalStorageData from "../../utils/getLocalStorageData";
import { saveUserDetails } from "../authentication/userReducer/userReducer";
import { fetchAPI } from "../../lib/api";


export const ForCanvasEditor = () => {
    const { editor, onReady, selectedObjects } = useFabricJSEditor()
    // const [pannelType, setPannelType] = useState('Solar Panel');
    const [pannelType, setPannelType] = useState('');
    const editorMemo: any = useMemo(() => editor?.canvas, [editor?.canvas]);
    const [netInfo, setNetInfo]: any = useState([])
    const [showDelete, setShowDelete] = useState(false)
    const [hasNextLine, setHasNextLine] = useState(false)
    const [movedObj, setMovedObj]: any = useState({})
    const [pxValue] = useState(0.8)   //1px = .8 inch  //1 inch = 1.26px //.5 inch = .625px
    const [panningMode, setPanningMode] = useState(false)
    const [loadJson, setLoadJson] = useState(true)
    const [quoteData, setQuoteData]: any = useState({});
    const [openEdit, setOpenEdit] = useState(false);
    const [lastObj, setLastObj]: any = useState({});
    const [selectionType, setSelectionType] = useState('');
    const [selected, setSelected] = useState(false);
    const [selectedNet, setSelectedNet]: any = useState({});
    const [addNext, setAddNext] = useState(false);
    const [lastSupport, setLastSupport]: any = useState({});
    const [inchValue] = useState(1.25);
    const [lastColor, setLastColor] = useState('');
    const [loader, setLoader] = useState(false);
    const [drawingMode, setDrawingMode] = useState(false);
    const [threePointStart, setThreePointStart] = useState(false);
    const [points, setPoints]: any = useState([]);
    const [polyData, setPolyData]: any = useState({});
    const [startingPoint, setStartingPoint]: any = useState(null);
    const [addCornerSupport, setAddCornerSupports] = useState(false);
    const [lockCanvas, setLockCanvas] = useState(false);
    const [movePoint, setMovePoint] = useState(false);
    const [netObj, setNetObj]: any = useState({});
    const [showAddNet, setShowAddNet]: any = useState(false);
    const [showCancel, setShowCancel]: any = useState(false);
    const [editPanelData, setEditPanelData]: any = useState();
    const [showResetModal, setShowResetModal]: any = useState(false);
    const [panelResetConfirmVal, setPanelResetConfirmVal]: any = useState(false);
    const [cancelPanelModal, setCancelPanelModal]: any = useState(false);
    const ZOOM_PERCENT = 1.2;
    const NET_MAX_LIMIT = 7

    const [btnLoader, setBtnLoader] = useState(false)
    const [openMagicForm, setOpenMagicForm] = useState(false)
    const [magicLinkType, setMagicLinkType] = useState('')
    const [magicFormBtnLoader, setMagicFormBtnLoader] = useState(false)
    const [magicConfirmOpen, setMagicConfirmOpen] = useState(false)
    const [mailCheckModalOpen, setMailCheckModalOpen] = useState(false)
    const [nameText, setNameText] = useState('');

    const [isDefaultPanelModalOpen, setIsDefaultPanelModalOpen] = useState(false);
    //Default Panel Data
    const [defaultPanels, setDefaultPanels] = useState([])
    const [selectedPanels, setSelectedPanels] = useState([]);
    const [panleLibLoginMsg, setPanleLibLoginMsg] = useState(false) // Used to show msg in panel library modal when user is logged out and try to add panel.
    const [deleteNetId, setDeleteNetId] = useState('');
    const [openDeleteNet, setOpenDeleteNet] = useState(false);
    const dispatch = useDispatch()

    const handlePanleLibLoginMsg = () => {
        setPanleLibLoginMsg(false);
    }

    const [panelObj, setPanelObj]: any = useState({
        left: 50,
        top: 100,
        fill: '#013A7D',
        hasControls: false,
        name: 'Panel 1',
        objectType: 'panel',
        perPixelTargetFind: true
    })

    const [textObj]: any = useState({
        fontSize: 18,
        fontFamily: 'helvetica',
        fill: 'white',
        lockUniScaling: true,
        textBackgroundColor: '#000000',
        lockRotation: true,
        hasControls: false,
        hasRotatingPoint: false,
        objectType: 'netValue',
        lockMovementX: true, // Lock horizontal movement
        lockMovementY: true, // Lock vertical movement
        cornerStyle: 'circle'
    })

    const [support]: any = useState({
        left: 50,
        top: 100,
        width: 10.7,
        height: 10.7,
        hasControls: false,
        lockRotation: true,
        hasRotatingPoint: false,
        objectType: 'support',
    })

    const [textBox]: any = useState({
        width: 100,
        fontSize: 18,
        fontFamily: 'helvetica',
        textAlign: 'left',
        fixedWidth: 50,
        lockScalingFlip: true,
        objectType: 'netname',
        hasControls: false,
        editable: true,
        lockMovementX: true, // Lock horizontal movement
        lockMovementY: true, // Lock vertical movement
        textBackgroundColor: '#ffff',
        editingBorderColor: 'red',
        cornerColor: 'blue',
        borderColor: 'blue',

    })

    const [golfNet, setGolfNet]: any = useState({
        width: 225,
        height: 96.7,
        opacity: 0.4,
        lockRotation: true,
        hasRotatingPoint: false,
        objectType: 'net',
        perPixelTargetFind: true
    })

    const [polygonObj]: any = useState({
        fill: 'yellow',
        selectable: true,
        hasControls: false,
        opacity: 0.3,
        lockRotation: true,
        hasRotatingPoint: false,
        objectType: 'net',
        perPixelTargetFind: true,
        lockMovementX: true, // Lock horizontal movement
        lockMovementY: true, // Lock vertical movement
        borderDashArray: [3, 3],
        hasBorders: true,
        borderColor: 'red'
    })


    const [customPanelArr, setCustomPanelArr]: any = useState([])
    const handleOpenEdit = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        editorMemo.discardActiveObject().renderAll();
        setOpenEdit(!openEdit);
    }

    const handleOutclickEdit = () => {
        setOpenEdit(false);
    }

    const onQuoteNameChange = (event: any) => {
        // setQuoteData({ ...setQuoteData, quoteName: event.target.value })
        let data = { ...quoteData }
        data.quoteName = event.target.value
        setQuoteData(data)

    }

    const customValueArr = ['name', 'id', 'objectType', 'netId', 'netColour', 'thickness', 'area', 'sides', 'panelId']

    const propertiesToInclude = ['hasControls', 'hasRotatingPoint', 'lockUniScaling', 'lockRotation', 'lockMovementX', 'lockMovementY']

    const solarnetId = process.env.REACT_APP_PUBLIC_solarNetId ?? '';
    const ballnetId = process.env.REACT_APP_PUBLIC_ballNetId ?? '';
    const catcherNetId = process.env.REACT_APP_PUBLIC_catcherNetId ?? '';

    const sideSId = process.env.REACT_APP_PUBLIC_sideSupportId ?? '';
    const cornerSId = process.env.REACT_APP_PUBLIC_cornerSupportId ?? '';
    const valleySId = process.env.REACT_APP_PUBLIC_valleySupportId ?? '';
    const threePointSId = process.env.REACT_APP_PUBLIC_threePointSupportId ?? '';
    const netItems = useSelector((state: any) => state.diagramReducer?.getAllSupportList);


    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handlePannelType = (data: any) => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        setPannelType(data);
    }

    const handleClearPanelType = () => {
        setPannelType('')
    }

    const openMagicModal = () => {
        setOpenMagicForm(true);
    }

    const closeMagicModal = () => {
        setOpenMagicForm(false);
    }

    const handleCancelMagiModal = () => {
        setOpenMagicForm(false);
        setMagicConfirmOpen(true);
    }

    const closeMagicConfirmModal = (type?: string) => {
        if (type === 'yes') {// For clearing save net Info
            console.log('yes cancel *')
            setNetInfo([])
            setMagicConfirmOpen(false)
        } else {
            setMagicConfirmOpen(false)
        }
    }

    const netSupportRedirect = () => {
        const userId = getLocalStorageData('userId');
        const quoteId = getLocalStorageData('quoteId');
        navigate(`/quote/netsupport?userId=${userId}&quoteId=${quoteId}&index=${searchParams.get('index') ?? 0}`);
    }

    const handlecloseCheckMailModal = () => {
        setMailCheckModalOpen(false)
    }

    const [openPanelModal, setOpenPanelModal] = useState(false)
    const [blockPickerColor, setBlockPickerColor]: any = useState("#013A7D")

    const handlePanelModal = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        else if (!searchParams.get('link') && !searchParams.get('quoteId')) {
            setOpenMagicForm(true);
        } else {
            setOpenPanelModal(true);
            setBlockPickerColor("#013A7D");
        }
    }
    const handleClosePanelModal = () => {
        setOpenPanelModal(false)
        setEditPanelData()
    }

    const handleColorPicker = (data: any) => {
        setBlockPickerColor(data)
    }

    const handlePanelEdit = (panelData: any) => {
        if (!isActionable(panelData)) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Unable to edit, panel is in use`, alertType: "error", borderClass: "error" }));
        }
        else {
            setOpenPanelModal(true);
            setEditPanelData(panelData)
            setBlockPickerColor(panelData.fill);
        }
    }

    const handleResetModal = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        setPanelResetConfirmVal(true)
        setShowResetModal(!showResetModal)
    }

    const onConfirmPanelReset = () => {
        setShowResetModal(!showResetModal)
    }

    const handlePanelCancelModal = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        setCancelPanelModal(!cancelPanelModal)
    }

    const getNetPosition = () => {
        const jsonObj = editorMemo.toJSON();
        const netArr = jsonObj?.objects.filter((data: any) => { return data.objectType === 'net' })
        let returnObj;
        const activeObj = editorMemo.getActiveObject()
        if (activeObj && Object.keys(activeObj)?.length > 0 && activeObj.objectType === 'panel') {
            returnObj = { left: activeObj.left - 5, top: activeObj.top - 5 }
        }
        else if (netArr?.length > 0) {
            if (movedObj && Object.keys(movedObj).length > 0 && movedObj.objectType === 'net') {
                returnObj = { left: (movedObj.width * movedObj.scaleX) + movedObj.left + 5, top: movedObj.top }
            }
            else {
                let data = netArr[netArr.length - 1]
                returnObj = { left: (data.width * data.scaleX) + data.left + 5, top: data.top }

            }

        }
        else {
            const arr = jsonObj?.objects.filter((data: any) => { return data.objectType === 'panel' })
            let panel = arr[0]
            returnObj = { left: panel.left - 5, top: panel.top - 5 }
        }

        return returnObj;
    }

    // This function not in use
    const onAddNet = (data: any) => {

        //Adding net
        const id = uuidv4();
        const netObj = { ...golfNet }
        netObj.netId = data.type === 'solarnet' ? solarnetId : data.type === 'ballnet' ? ballnetId : catcherNetId
        netObj.name = data.type
        netObj.id = id
        netObj.netColour = data.colour === 'white' ? '#FFFFFF' : '#D9D9D9'
        let gardientData;

        if (data.type === 'solarnet' && data.colour === 'white') {
            gardientData = new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'percentage',
                coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
                colorStops: [
                    { offset: 0, color: 'red' },
                    { offset: 1, color: 'blue' }
                ]
            })

        } else if (data.type === 'solarnet' && data.colour === 'black') {
            gardientData = new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'percentage',
                coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
                colorStops: [
                    { offset: 0, color: 'yellow' },
                    { offset: 1, color: 'green' }
                ]
            })
        } else if (data.type === 'ballnet' && data.colour === 'white') {
            gardientData = new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'percentage',
                coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
                colorStops: [
                    { offset: 0, color: 'black' },
                    { offset: 1, color: 'white' }
                ]
            })
        } else {
            gardientData = new fabric.Gradient({
                type: 'linear',
                gradientUnits: 'percentage',
                coords: { x1: 0, y1: 0, x2: 1, y2: 0 },
                colorStops: [
                    { offset: 0, color: 'pink' },
                    { offset: 1, color: 'blue' }
                ]
            })
        }

        let positionObj = getNetPosition()
        const net = new fabric.Rect({ ...netObj, ...positionObj });
        net.set('fill', gardientData)
        editorMemo.add(net);

        addAdditionalData(netObj, positionObj)

        let netArr: any = [...netInfo]
        let obj: any = {
            height: formatNumber(((netObj.height * pxValue) / 12)),
            width: formatNumber(((netObj.width * pxValue) / 12)),
            id: id
        }
        obj.area = formatNumber(obj.height * obj.width);
        netArr.push(obj)
        setNetInfo(netArr)

    }

    const addAdditionalData = (netObj: any, positionObj: any) => {
        //To show height width of the net
        let string = `  H:${formatNumber(((netObj.height * pxValue) / 12))}, W:${formatNumber(((netObj.width * pxValue) / 12))}  `
        let textObject = { ...textObj }
        textObject.id = `text-${netObj.id}`;
        textObject.left = positionObj.left
        textObject.top = positionObj.top - 20
        const text = new fabric.Text(string, textObject)
        editorMemo.add(text);

        //To enter custom net name

        const textBoxObj = { ...textBox }
        textBoxObj.id = `name-${netObj.id}`
        textBoxObj.left = positionObj.left
        textBoxObj.top = positionObj.top + netObj.height + 10
        let name = nameText?.length > 0 && nameText != 'Name' ? nameText : 'Name'
        const box: any = new fabric.IText(name, textBoxObj)
        box.on('editing:entered', () => {
            box.hiddenTextarea.setAttribute('maxlength', 80);
        })
        editorMemo.add(box);
    }


    const checkForObj = (type: string, activeObj: any) => {
        let result = false
        let addObj = { ...panelObj }
        addObj.left = activeObj?.left + activeObj?.width
        let rect = new fabric.Rect(addObj);
        editorMemo.forEachObject(function (targ: any) {
            if (rect.isContainedWithinObject(targ) || targ.isContainedWithinObject(rect)) {
                result = true;
            }
        });
        return result;
    }


    const getLastObj = (type: string) => {
        let lastObj: any = {}

        const activeObj = editorMemo.getActiveObject();


        if (activeObj && Object.keys(activeObj)?.length > 0 && activeObj.objectType === 'panel' && !checkForObj(type, activeObj) && addNext) {
            setAddNext(false);
            lastObj = { ...activeObj };
        }
        else if (movedObj && Object.keys(movedObj)?.length > 0 && movedObj.objectType === 'panel') {
            lastObj = { ...movedObj }
        }
        else {
            const jsonObj = editorMemo.toJSON();
            const arr = jsonObj?.objects.filter((data: any) => { return data.objectType === 'panel' })
            if (arr?.length > 0) {
                lastObj = arr[arr.length - 1]
            }
        }
        return lastObj;
    }

    const onAddRectangle = (data: any) => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            // return;
        }
        removeBackgroundText();
        if (netObj && Object.keys(netObj)?.length > 0) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please save or cancel the current net design first`, alertType: "error", borderClass: "error" }));
            return;
        } else if (netInfo && netInfo.length >= NET_MAX_LIMIT) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Net maximum limit reached, you can add maximum 7 nets`, alertType: "error", borderClass: "error" }));
            return;
        }
        const lastObj = getLastObj(data.type)
        let obj: any = { ...panelObj }

        obj.height = data.height;
        obj.width = data.width;
        obj.name = data.name;
        obj.thickness = data.thickness ? Number(data.thickness) : 37
        obj.panelId = data.id;
        if (data?.fill) {
            obj.fill = data.fill
        }
        else {
            obj.fill = '#013A7D'
        }

        if (lastObj?.left) {
            if (lastObj?.left > (editorMemo.width - 200)) {
                setHasNextLine(true)
                obj.top = lastObj.top + lastObj?.height + 0.625
                setPanelObj(obj);
            }
            else {
                if (!hasNextLine) {
                    obj.top = lastObj.top
                    obj.left = (lastObj?.left + lastObj?.width + 0.625)
                }
                setHasNextLine(false)
            }
        }
        const rect = new fabric.Rect(obj);
        editorMemo.add(rect);
        setMovedObj({})

    }


    const getScaledData = useCallback(
        (options: any) => {
            const ObjArr = editorMemo.getObjects()
            const textObj = ObjArr.find((data: any) => { return data.id === `text-${options.target.id}` })
            if (textObj) {
                let height = formatNumber((((options.target.height * options.target.scaleY) * pxValue) / 12))
                let width = formatNumber((((options.target.width * options.target.scaleX) * pxValue) / 12))
                let string = `  H:${height}, W:${width}  `
                textObj.set({ text: string, top: (options.target.top - 20), left: options.target.left })

                let netArr: any = [...netInfo]
                const index = netArr.findIndex((obj: { id: string }) => obj.id === options.target.id)
                if (index > -1) {
                    netArr[index].height = height;
                    netArr[index].width = width;
                    netArr[index].area = formatNumber(Number(height) * Number(width));
                }
                setNetInfo(netArr)
            }
            const nameObj = ObjArr.find((data: any) => { return data.id === `name-${options.target.id}` })
            if (nameObj) {
                nameObj.enterEditing();
                const height = (options.target.height * options.target.scaleY) + options.target.top
                nameObj.set({ top: height + 2, left: options.target.left })
            }
            editorMemo.renderAll();
        },
        [editor]
    );


    const handleObjectMoving = useCallback(
        (options: any) => {

            options.target.setCoords();
            setMovedObj(options.target);
            if (options.target.objectType === 'net') {
                const ObjArr = editorMemo.getObjects()
                const textObj = ObjArr.find((data: any) => { return data.id === `text-${options.target.id}` })
                if (textObj) {
                    textObj.set({ top: (options.target.top - 20), left: options.target.left })
                }
                const nameObj = ObjArr.find((data: any) => { return data.id === `name-${options.target.id}` })
                if (nameObj) {
                    nameObj.enterEditing();
                    const height = (options.target.height * options.target.scaleY) + options.target.top
                    nameObj.set({ top: height + 2, left: options.target.left })
                }
            }
            else if (options.target.objectType === 'panel') {
                intersectFun(options, editorMemo)
            }
            else if (options.target.objectType === 'support') {
                // console.log(options.target)
            }
            editorMemo.renderAll();


        }, [editor])


    const onTextChanged = useCallback(
        (options: any) => {
            if (options.target.type === 'textbox' || options.target.type === 'i-text') {
                const netArr: any = [...netInfo]
                const index = netArr.findIndex((obj: any) => options.target.id.includes(obj.id))
                if (index > -1) {
                    netArr[index].itemName = options.target.text;
                }
                else if (netObj && Object.keys(netObj)?.length > 0 && options.target.id.includes(netObj.id)) {
                    netObj.itemName = options.target.text;
                }
                setNetObj(netObj);
                // setNetInfo(netArr);
            }
        }, [editor])



    const getSVGfile = () => {
        const svg = editorMemo.toSVG();
        console.log("svg", svg);
    };

    const getJSONdata = () => {
        const jsonObj = editorMemo.toJSON();
        const output = JSON.stringify(jsonObj, null, "\t");
    };

    const intersectingCheck = (activeObject: any) => {
        activeObject.setCoords();
        if (typeof activeObject.refreshLast != 'boolean') {
            activeObject.refreshLast = true
        };
        activeObject.canvas.forEachObject(function (targ: any) {
            if (targ === activeObject) return; //bypass self

            //check intersections with every object in canvas
            if (activeObject.intersectsWithObject(targ)
                || activeObject.isContainedWithinObject(targ)
                || targ.isContainedWithinObject(activeObject)) {
                //objects are intersecting - deny saving last non-intersection position and break loop
                if (typeof activeObject.lastLeft == 'number') {
                    activeObject.left = activeObject.lastLeft + 0.625;
                    activeObject.top = activeObject.lastTop;
                    activeObject.refreshLast = false;
                    return;
                }
            }
            else {
                activeObject.refreshLast = true;
            }
        });

        if (activeObject.refreshLast) {       //save last non-intersecting position
            activeObject.lastLeft = activeObject.left
            activeObject.lastTop = activeObject.top;
        }
        editorMemo.renderAll();
    }



    const updateSelectedObject = useCallback(
        (options: any) => {
            setAddNext(true);
            const selectedData = options.selected[0];
            if (lastObj && lastObj?.objectType === 'panel') {
                lastObj.set({ fill: lastColor })
            }
            if (selectedData.objectType === 'panel') {
                setLastColor(options.selected[0].fill)
                setLastObj(options.selected[0])
                selectedData.set({ selectionColor: '#4899DE', fill: '#4899DE' })
            }

            else if (selectedData.objectType === 'support') {
                setLastObj({});
                setLastColor('');
                selectedData.set({ height: support.height, width: support.width });
                setLastSupport({});
            }

            else {
                selectedData.set({ borderColor: 'orange', strokeWidth: 3 })
                setLastObj({});
                setLastColor('');
                if (selectedData.objectType === 'net') {
                    setSelectionType(selectedData.objectType);
                    setSelectedNet(selectedData);
                }
            }
            editorMemo.renderAll();
        },
        [editor]
    );



    useEffect(() => {
        if (editor) {
            editorMemo.backgroundColor = "white";
            editorMemo.renderingBackend = 'webgl';
            editorMemo.renderOnAddRemove = false;
            fabric.IText.prototype.borderColor = 'blue';

            fabric.Image.fromURL(wbg, function (img: any) {
                img.set({
                    left: 0,
                    top: 0,
                    scaleX: editorMemo.width / img.width,
                    scaleY: editorMemo.height / img.height
                });
                editorMemo.setBackgroundImage(img);
                editorMemo.renderAll();
            });

            editorMemo.on("object:moving", handleObjectMoving);
            editorMemo.on("object:scaling", getScaledData);
            editorMemo.on(
                {
                    'selection:updated': updateSelectedObject,
                    'selection:created': updateSelectedObject
                })

            editorMemo.on("selection:cleared", function (opt: any) {
                let obj: any;
                if (opt.deselected?.length > 0) {
                    obj = opt.deselected[0];
                }
                else {
                    obj = opt.target;
                }
                if (selectedNet && selectedNet?.objectType) {
                    selectedNet.set({ evented: true })
                }
                setSelectionType('');
                if (obj.objectType === 'panel') {
                    obj.set({ fill: lastColor })
                }
                else if (obj.type === 'i-text' && !obj.text?.length) {
                    obj.enterEditing();
                }
                setLastObj({});
                setLastColor('');
                setSelectedNet({});
                setShowDelete(false);
                setAddCornerSupports(false);

            })

            editorMemo.on("mouse:over", increaseSupportSize);
            editorMemo.on("mouse:out", backToNormalSupport);
            editorMemo.on("text:changed", onTextChanged);

            // editorMemo.on('mouse:wheel', function (opt: any) {
            //     var delta = opt.e.deltaY;
            //     var zoom = editorMemo.getZoom();
            //     zoom *= 0.999 ** delta;
            //     if (zoom > 20) zoom = 20;
            //     if (zoom < 0.01) zoom = 0.01;
            //     editorMemo.setZoom(zoom);
            //     opt.e.preventDefault();
            //     opt.e.stopPropagation();
            // })

            const netObjects = editorMemo.getObjects()?.filter((data: any) => { return data.objectType === 'net' })


            if (netObjects?.length === 1) {
                setShowAddNet(true);
            }
            else {
                setShowAddNet(false);
            }


            // const renderObjects = editorMemo.getObjects()?.filter?.((data: any) => { return data.objectType !== 'backgroundtext' })
            // if (renderObjects?.length > 0) {
            //     setShowCancel(true);
            //     removeBackgroundText()
            // }
            // else {
            //     addBackgroundText();
            //     setShowCancel(false);
            // }

            const activeObj = editorMemo.getActiveObject();
            if (activeObj && Object.keys(activeObj)?.length > 0) {
                setShowDelete(true)
                if (activeObj?.type === 'polygon') {
                    setAddCornerSupports(true);
                }
            }
            const firstPanel = new Image();
            firstPanel.src = panel1;

            const secondPanel = new Image();
            secondPanel.src = panel2;

            document.addEventListener("dragstart", function (event: any) {
                if (event.stopPropagation) {
                    event.stopPropagation(); //Stops some browsers from redirecting.
                }
                let data = JSON.parse(event.target.id)
                if (data.type === 'panel') {
                    if (data.name === 'Panel 1') {
                        event.dataTransfer.setDragImage(firstPanel, 0, 0);
                    }
                    else {
                        event.dataTransfer.setDragImage(secondPanel, 0, 0);
                    }
                }
                event.dataTransfer.setData("dragId", event.target.id);

            });


            document.addEventListener("drop", handleDrop);


            let quoteId = searchParams.get('quoteId') || '';
            if (quoteId?.length > 0 && loadJson) {
                getQuotes(quoteId);
                getUserPanel();
                setLoadJson(false);
            }

            const originalToObject = fabric.Object.prototype.toObject;
            fabric.Object.prototype.toObject = function (additionalProperties: any) {
                return originalToObject.call(this, customValueArr.concat(additionalProperties));
            }


            document.addEventListener("dblclick", removePanning);


            document.addEventListener("keydown", listenKeyBoard);


            return () => {
                editorMemo.off("object:moving");
                editorMemo.off("object:scaling");
                editorMemo.off('selection:updated')
                editorMemo.off('selection:created')
                editorMemo.off("selection:cleared")
                editorMemo.on("mouse:over");
                editorMemo.on("mouse:out");
                document.removeEventListener("drop", handleDrop);
                document.removeEventListener("dragstart", function (event: any) {
                    if (event.stopPropagation) {
                        event.stopPropagation(); //Stops some browsers from redirecting.
                    }
                    let data = JSON.parse(event.target.id)
                    if (data.type === 'panel') {
                        if (data.name === 'Panel 1') {
                            event.dataTransfer.setDragImage(firstPanel, 0, 0);
                        }
                        else {
                            event.dataTransfer.setDragImage(secondPanel, 0, 0);
                        }
                    }
                    event.dataTransfer.setData("dragId", event.target.id);
                    event.dataTransfer.setData("dragId", event.target.id);

                });
                document.removeEventListener("dblclick", removePanning);
                document.removeEventListener("keydown", listenKeyBoard);
            };

        }
    }, [editor, loadJson]);

    const spanning = () => {
        setPanningMode(!panningMode)
        toggleDragMode(!panningMode)
        if (!panningMode) {
            editorMemo.defaultCursor = 'grabbing'
        }
        else {
            editorMemo.defaultCursor = 'default'
        }
    }


    const removePanning = (e: any) => {
        if (panningMode) {
            setPanningMode(false)
            toggleDragMode(false)
            editorMemo.defaultCursor = 'default'
        }
    }


    const onDeleteSelection = async () => {
        console.log("onDeleteSelection ##");
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        const activeSelection = editorMemo.getActiveObject();
        console.log(activeSelection, 'activeSelection ##');
        if (activeSelection && activeSelection._objects) {
            activeSelection._restoreObjectsState();
            editorMemo.remove(activeSelection);
            activeSelection._objects.map((object: any) => editorMemo.remove(object));
            editorMemo.renderAll();
        } else {
            const ObjArr = editorMemo.getObjects()
            if (activeSelection.objectType === 'net') {
                const textObj = ObjArr.find((data: any) => { return data.id === `text-${activeSelection.id}` })
                if (textObj) {
                    editorMemo.remove(textObj);
                }
                const nameObj = ObjArr.find((data: any) => { return data.id === `name-${activeSelection.id}` })
                if (nameObj) {
                    editorMemo.remove(nameObj);
                }

                const netSupports = ObjArr.filter((obj: any) => { return (obj.objectType === 'support' && obj.id.includes(activeSelection.id)) })

                if (netSupports?.length > 0) {
                    await Promise.all(
                        netSupports.map(async (data: any) => {
                            await editorMemo.remove(data);
                        })
                    )
                }


                const netInfoArr = netInfo.filter((obj: any) => { return obj.id != activeSelection.id })
                setNetInfo(netInfoArr);
                if (activeSelection.type === 'polygon') {
                    const points = ObjArr.filter((obj: any) => { return (obj.objectType === 'point' && obj.id.includes(activeSelection.id)) })
                    if (points?.length > 0) {
                        await Promise.all(
                            points.map(async (data: any) => {
                                await editorMemo.remove(data);
                            })
                        )
                    }

                }

            }
            editorMemo.remove(activeSelection);
        }
        setShowDelete(false);
    };


    const clearCanvas = () => {
        if (editor) {
            resetData();
            setNetInfo([]);
            editorMemo.clear();
            addBackgroundText();
            editorMemo.renderAll();
            setShowResetModal(!showResetModal)
        }
    }

    const resetData = () => {
        setNetObj({});
        setMovedObj({});
        setLastObj({});
        setPolyData({});
        closeDrawingMode();
        setLockCanvas(false);
        setShowCancel(false);
        setThreePointStart(false);
        setNameText('');
    }

    const getImageFile = async (dataUrl: string, fileName: string): Promise<File> => {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    const [openSupportConfirmModal, setOpenSupportConfirmModal] = useState(false)



    const handleSaveCanvasData = () => {
        setOpenSupportConfirmModal(false)
    }

    const onConfirmHandler = () => {
        setOpenSupportConfirmModal(false)
        saveNetInfo();
    }

    const saveCanvasData = async (QuoteId?: string, UserId?: string) => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        const user = getLocalStorageData('user')
        const token = getLocalStorageData('token')
        // if(user?.quoteId === null){// This conditon is for creating a new quote after successful payment of first quote.
        //     QuoteCreate();
        // }
        if (netInfo && netInfo?.length === 0) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `No design found`, alertType: "error", borderClass: "error" }));
        }
        else if (!searchParams.get('quoteId') && !UserId && !token) {
            setOpenMagicForm(true);
            setMagicLinkType('QuoteCreate');
        }
        else if (lockCanvas) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please Unlock the canvas & save`, alertType: "error", borderClass: "error" }));
        }
        else {
            setLoader(true);
            if (netInfo?.length > 0) {
                console.log(!searchParams.get('quoteId'), (!localStorage.getItem("quoteId") || localStorage.getItem("quoteId") === 'undefined' || localStorage.getItem("quoteId") === undefined), !user?.quoteId, "checking *")
                const netArr = await getNetArrBody(netInfo)
                if (!searchParams.get('quoteId') && (!localStorage.getItem("quoteId") || localStorage.getItem("quoteId") === 'undefined' || localStorage.getItem("quoteId") === undefined) && !user?.quoteId) {
                    console.log("quoteId not found *")
                    await QuoteCreate();
                }
                else if (netArr?.length > 0) {
                    console.log("next if *")
                    let body = {
                        item: netArr,
                        canvasData: netArr[0].json,
                        diagram: null,
                        quoteName: quoteData.quoteName,
                    }
                    let quoteId = searchParams.get('quoteId') || localStorage.getItem("quoteId") || '';
                    let userId = UserId ? UserId : searchParams.get('userId') || '';
                    localStorage.setItem('quoteId', quoteId);
                    localStorage.setItem('userId', userId);
                    console.log(quoteId, "quoteId 001")
                    // quoteId = quoteId.replace(/[^a-zA-Z0-9]/g, '')
                    // console.log(quoteId, "quoteId 002")
                    await updateQuote(body, quoteId).then(async (res: any) => {
                        if (!res?.error) {
                            dispatch(getQuoteData(res.result));
                            dispatch(getDiagramSuccess(res?.result?.items));
                            if (magicLinkType !== 'QuoteCreate' && quoteId && userId) {
                                navigate(`/quote/netsupport?userId=${userId}&quoteId=${quoteId}&index=${searchParams.get('index') ?? 0}`); // comment to open check for email modal
                            }
                            // else if(getLocalStorageData('token')) {
                            //     navigate(`/quote/netsupport?userId=${userId}&quoteId=${quoteId}&index=${searchParams.get('index') ?? 0}`);
                            // }
                            // navigate(`/quote/netsupport?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}`);    // commented for Magic link changes
                        }
                        else {
                            // navigate(`/quote/netsupport?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index')?? 0}`);  // For bypassing Net update  
                            dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
                        }
                    })
                }

            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `No design found`, alertType: "error", borderClass: "error" }));
            }
            setLoader(false)
            unlockCanvasEditor();
        }
    }

    //Qutoe Create
    const QuoteCreate = () => {
        const userId = getLocalStorageData('userId');
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes`,
            bodyData: {
                userId: userId,
                betaVersion: true
            }
        }
        doPostApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    localStorage.setItem('quoteId', res?.result?._id);
                    saveCanvasData(res?.result?._id)
                    // navigate(`/quote/addshippingaddr?userId=${userId}&quoteId=${res?.result?._id}&index=${searchParams.get('index') ?? 0}`)
                }
            })
            .catch((err: any) => {
                console.log(err);
            })
    }


    const updateZoom = useCallback(
        (event: any, type: string) => {
            let delta;

            if (threePointStart) {
                checkForThreePointStart(threePointStart);
                return;
            } else if (type === 'zoomin') {
                delta = editorMemo.getZoom() * ZOOM_PERCENT
            }
            else {
                delta = editorMemo.getZoom() / ZOOM_PERCENT
            }
            editorMemo.setZoom(delta);
            editorMemo.setViewportTransform(editorMemo.viewportTransform);
            event.preventDefault();
            event.stopPropagation();
            editorMemo.renderAll();

        },
        [editor]
    );


    // For Canvas panning functionality
    const STATE_IDLE = 'idle';
    const STATE_PANNING = 'panning';
    const toggleDragMode = (dragMode: any) => {
        // Remember the previous X and Y coordinates for delta calculations
        let lastClientX: any;
        let lastClientY: any;
        // Keep track of the state
        let state = STATE_IDLE;
        // We're entering dragmode
        if (dragMode) {
            // Discard any active object
            editorMemo.discardActiveObject();
            // Set the cursor to 'move'
            editorMemo.defaultCursor = 'move';
            // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
            editorMemo.forEachObject(function (object: any) {
                object.prevEvented = object.evented;
                object.prevSelectable = object.selectable;
                object.evented = false;
                object.selectable = false;
            });
            // Remove selection ability on the canvas
            editorMemo.selection = false;
            // When MouseUp fires, we set the state to idle
            editorMemo.on('mouse:up', function (e: any) {
                state = STATE_IDLE;
            });
            // When MouseDown fires, we set the state to panning
            editorMemo.on('mouse:down', (e: any) => {
                state = STATE_PANNING;
                lastClientX = e.e.clientX;
                lastClientY = e.e.clientY;
            });
            // When the mouse moves, and we're panning (mouse down), we continue
            editorMemo.on('mouse:move', (e: any) => {
                if (state === STATE_PANNING && e && e.e) {
                    // Calculate deltas
                    let deltaX = 0;
                    let deltaY = 0;
                    if (lastClientX) {
                        deltaX = e.e.clientX - lastClientX;
                    }
                    if (lastClientY) {
                        deltaY = e.e.clientY - lastClientY;
                    }
                    // Update the last X and Y values
                    lastClientX = e.e.clientX;
                    lastClientY = e.e.clientY;

                    let delta = new fabric.Point(deltaX, deltaY);
                    editorMemo.relativePan(delta);
                }
            });
        } else {
            // When we exit dragmode, we restore the previous values on all objects
            editorMemo.forEachObject(function (object: any) {
                object.evented = (object.prevEvented !== undefined) ? object.prevEvented : object.evented;
                object.selectable = (object.prevSelectable !== undefined) ? object.prevSelectable : object.selectable;
            });
            // Reset the cursor
            editorMemo.defaultCursor = 'default';
            // Remove the event listeners
            editorMemo.off('mouse:up');
            editorMemo.off('mouse:down');
            editorMemo.off('mouse:move');
            // Restore selection ability on the canvas
            editorMemo.selection = true;
        }
    };


    const getQuotes = (quoteId: string) => {
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes/${quoteId}`,
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    dispatch(getQuoteData(res.result));
                    setQuoteData(res.result)
                    editorMemo.clear();
                    let netArr: any = [];
                    if (res.result?.canvasData?.length > 0) {
                        if (res.result?.items.length > 0 && res.result?.items[0]?.json) {
                            netArr = res.result?.items.map((data: any, index: number) => {
                                let obj = { ...data }
                                const json = JSON.parse(data.json)
                                let netdata = json?.objects?.find((data: any) => { return data.objectType === 'net' })
                                if (netdata) {
                                    obj.id = netdata.id
                                }
                                if (data?.net && data?.net?.price && data?.net?.units) {
                                    obj.unitPrice = Number(data?.net?.price);
                                    obj.units = data?.net?.units;
                                }
                                return obj;
                            })
                        }
                        else {
                            editorMemo.loadFromJSON(res.result?.canvasData, editorMemo.renderAll.bind(editorMemo));
                            const allObj = JSON.parse(res.result?.canvasData).objects;
                            const arr: any = allObj?.filter((data: any) => { return data?.objectType === 'net' })

                            netArr = arr.map((data: any, index: number) => {
                                let nameObj = allObj?.find((obj: any) => { return obj.id === `name-${data.id}` });
                                let obj: any = {
                                    id: data.id,
                                    itemName: nameObj.text,
                                    height: formatNumber((((data.height * data.scaleY) * pxValue) / 12)),
                                    width: formatNumber((((data.width * data.scaleX) * pxValue) / 12)),
                                }
                                obj.area = formatNumber(obj.height * obj.width);
                                return obj;
                            })

                        }
                        console.log(netArr, 'netArr #');
                        setNetInfo(netArr);
                    }
                    addBackgroundText();

                }
            })
            .catch((err: any) => {
                console.log(err, "<<-- err")
            })
    }





    const enableDisableClickThrough = () => {
        setSelected(!selected);
        const activeObj = editorMemo.getActiveObject()
        activeObj.set({ evented: selected })
        editorMemo.renderAll();
        if (selected) {
            setSelectionType('');
        }
    }

    //To add support or panel by dragging
    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        let dragId = event.dataTransfer.getData("dragId");
        let data = JSON.parse(dragId);
        const jsonObj = editorMemo.toJSON();
        const netArr = jsonObj?.objects.filter((data: any) => { return data.objectType === 'net' })
        if (data?.type?.toLowerCase().includes('support') && netArr.length > 0) {

            let obj = { ...support }
            if (data?.type === 'cornerSupport') {
                obj.fill = '#25be4b'
                obj.name = 'Corner Support'
            }
            else if (data?.type === 'valleySupport') {
                obj.fill = '#fcba03'
                obj.name = 'Valley Support'
            } else if (data?.type === 'threePointSupport') {
                obj.fill = '#1473E6'
                obj.name = 'Three Point Support'
            }
            else {
                obj.fill = '#ff2400'
                obj.name = 'Side Support'
            }
            obj.left = event.layerX
            obj.top = event.layerY
            const rect: any = new fabric.Rect(obj);
            editorMemo.forEachObject(function (targ: any) {
                if (targ.objectType === 'net' && (rect.isContainedWithinObject(targ) || rect.intersectsWithObject(targ))) {
                    rect.id = `${data?.type.toLowerCase()}-${targ.id}`
                    if (data?.type === 'cornerSupport') {
                        let cords: any = targ.type === 'polygon' ? targ.get("points") : targ.getCoords();
                        const xObj = getClosestValue(cords, event.layerX, 'x')
                        const yObj = getClosestValue(cords, event.layerY, 'y')
                        const selectedIndex = cords.findIndex((data: any) => data.x === xObj.x && data.y === yObj.y)
                        let setObj = {
                            left: xObj.x,
                            top: yObj.y
                        }
                        if (selectedIndex > -1) {
                            switch (selectedIndex) {
                                case 1:
                                    setObj.left = xObj.x - 10.7;
                                    break;
                                case 2:
                                    setObj.left = xObj.x - 10.7;
                                    setObj.top = yObj.y - 10.7;
                                    break;
                                case 3:
                                    setObj.top = yObj.y - 10.7;
                                    break;
                                default:
                                    console.log("Default Case");
                                    break;
                            }
                        }
                        rect.set(setObj)
                        editorMemo.add(rect);
                    }
                    else if (data?.type === 'sideSupport') {
                        let panelObjects = editorMemo.getObjects().filter((data: any) => { return data.objectType === 'panel' })
                        if (panelObjects.length > 0) {
                            let cordArr: any = []

                            for (let i = 0; i < panelObjects.length; i++) {
                                let obj1 = panelObjects[i].aCoords.br
                                obj1.type = 'right'
                                let obj3 = panelObjects[i].aCoords.tr
                                obj3.type = 'right-top'


                                let obj2 = panelObjects[i].aCoords.bl
                                obj2.type = 'left'
                                let obj4 = panelObjects[i].aCoords.tl
                                obj4.type = 'left-top'
                                if (i === 0) {
                                    cordArr.push(obj1, obj3);
                                }
                                else if (i === (panelObjects.length - 1)) {
                                    cordArr.push(obj2, obj4);
                                }
                                else {
                                    cordArr.push(obj1, obj2, obj3, obj4);
                                }
                            }

                            const xObj = getClosestValue(cordArr, event.layerX, 'x')
                            const yObj = getClosestValue(cordArr, event.layerY, 'y')

                            const index = cordArr.findIndex((data: any) => data.x === xObj.x && data.y === yObj.y)

                            let setObj = {
                                left: xObj.x,
                                top: yObj.y
                            }

                            if (index > -1) {
                                if (cordArr[index].type === 'right') {
                                    setObj.top = yObj.y - 10.7
                                }
                                else if (cordArr[index].type === 'left') {
                                    setObj.top = yObj.y - 10.7
                                }
                                if (cordArr[index].type === 'right-top') {
                                    setObj.left = xObj.x - 5.35
                                }
                                else {
                                    setObj.left = xObj.x - 5.35
                                }
                            }

                            rect.set(setObj)
                            editorMemo.add(rect);

                        }
                    }
                    else if (data?.type === 'valleySupport') {
                        rect.id = `${data?.type.toLowerCase()}-${targ.id}`
                        rect.set({ left: event.layerX, top: event.layerY })
                        editorMemo.add(rect);

                    } else if (data?.type === 'threePointSupport') {
                        rect.id = `${data?.type.toLowerCase()}-${targ.id}`
                        rect.set({ left: event.layerX, top: event.layerY })
                        editorMemo.add(rect);
                    }
                }


            });

        }
        else if (data?.type === 'panel') {
            removeBackgroundText();
            if (netObj && Object.keys(netObj)?.length > 0) {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Please save or cancel the current net design first`, alertType: "error", borderClass: "error" }));
                return;
            } else if (netInfo && netInfo.length >= NET_MAX_LIMIT) {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Net maximum limit reached, you can add maximum 7 nets`, alertType: "error", borderClass: "error" }));
                return;
            }
            let addObj = { ...panelObj }
            if (data?.fill) {
                addObj.fill = data.fill
            }
            else {
                addObj.fill = '#013A7D'
            }
            addObj.height = data.height;
            addObj.width = data.width;
            addObj.left = event.layerX;
            addObj.top = event.layerY;
            addObj.panelId = data.id;
            setPanelObj(addObj);
            const rect = new fabric.Rect(addObj);
            editorMemo.add(rect);

        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please add net first`, alertType: "error", borderClass: "error" }));
        }

    }

    const resetZoom = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        editorMemo.setZoom(1);
        editorMemo.renderAll()
    }

    const handleUndo = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        editorMemo.undo(function () {
            removeControl();
        });
    }

    const handleRedo = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        }
        editorMemo.redo(function () {
            removeControl();
        });
    }



    const removeControl = () => {
        editorMemo.forEachObject(function (targ: any) {
            if (targ.objectType === 'netValue' || targ.objectType === 'netname' || targ.type === 'polygon') {
                if (targ.type === 'polygon') {
                    targ.set({ lockRotation: true, hasRotatingPoint: false, lockUniScaling: true, lockMovementX: true, lockMovementY: true, lockScalingFlip: true, hasControls: false })
                }
                else {
                    targ.set({ selectable: false })
                }
            }
            else {
                targ.set({ lockRotation: true, hasRotatingPoint: false, hasControls: false })
            }


        })
        editorMemo.renderAll();
    }



    const getClosestValue = (data: any, target: any, param: string) =>
        data.reduce((acc: any, obj: any) =>
            Math.abs(target - obj[param]) < Math.abs(target - acc[param]) ? obj : acc
        );


    const increaseSupportSize = (e: any) => {
        const activeObj = editorMemo.getActiveObject();
        if (e.target?.objectType === 'support' && activeObj?.id !== e.target?.id) {
            setLastSupport(e.target);
            e.target.set({ height: 20, width: 20 })
        }
        else {
            setLastSupport({});
        }
    }

    const backToNormalSupport = (e: any) => {
        if (lastSupport && lastSupport.objectType === 'support' && e.target?.objectType === 'support') {
            lastSupport.set({ height: support.height, width: support.width });
            setLastSupport({});
        }
    }

    // create panel formik
    const panelForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            panelName: editPanelData?.name ? editPanelData?.name : "",
            height: editPanelData?.panelHeightNew ? editPanelData?.panelHeightNew : "",
            width: editPanelData?.panelWidthNew ? editPanelData?.panelWidthNew : "",
            thickness: editPanelData?.thickness ? editPanelData?.thickness : "",
            // color: editPanelData?.fill ? editPanelData?.fill : blockPickerColor
        },
        validationSchema: Yup.object().shape({
            panelName: Yup.string()
                .required("Please enter panel name"),
            height: Yup.number()
                .min(1, "Height value should be atleast 1")
                .required("Please enter height in millimeter"),
            width: Yup.number()
                .min(1, "Width value should be atleast 1")
                .required("Please enter width in millimeter"),
            thickness: Yup.number()
                .min(1, "thickness value should be atleast 1")
                .required("Please enter thickness in millimeter"),
            // color: Yup.string()
            //     .required("Please select panel color"),
        }),
        onSubmit: (values, { resetForm }) => {
            editPanelData !== undefined ? editPanel(values, resetForm) : createNewPanel(values, resetForm)
        },
    });

    const magicLinkForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: getLocalStorageData('email') ? getLocalStorageData('email') : "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email field cannot be empty"),
        }),
        onSubmit: (values, { resetForm }) => {
            createMagicLink(values, resetForm)
        },
    });


    const getUserPanel = () => {
        let userId = searchParams.get('userId') || '';
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/panels/${userId}`,
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    if (res.result.length > 0) {
                        let panelArr = res?.result?.map((data: any) => {
                            let obj: any = {
                                name: data.name,
                                type: 'panel',
                                title: `${data.name}(${formatNumber(Number(data?.height?.ft))}'${formatNumber(Number(data?.height?.inch))}”x${formatNumber(Number(data?.width?.ft))}'${formatNumber(Number(data?.width?.inch))}”)`,
                                fill: data?.color,
                                thickness: Number(data?.thickness) || 37,
                                id: data?._id,
                                panelHeightNew: data?.heightMm,
                                panelWidthNew: data?.widthMm
                            }
                            obj.height = formatNumber(((Number(data?.height?.ft) * 12) + Number(data?.height?.inch)) * inchValue)
                            obj.width = formatNumber(((Number(data?.width?.ft) * 12) + Number(data?.width?.inch)) * inchValue)
                            obj.users = data.users;
                            obj.dimension = `(${formatNumber(Number(data?.height?.ft))}'${formatNumber(Number(data?.height?.inch))}”x${formatNumber(Number(data?.width?.ft))}'${formatNumber(Number(data?.width?.inch))}”)`
                            return obj;
                        })
                        setCustomPanelArr(panelArr);
                    }
                } else if (res.result.length === 0) setCustomPanelArr([]);
            })
            .catch((err: any) => {
                console.log(err, "Get Panel err")
            })
    }

    // create panel POST api call 
    const createNewPanel = (panelData: any, resetForm: any) => {
        const userId = getLocalStorageData('userId');
        setLoader(true);
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/panels`,
            bodyData: {
                name: panelData?.panelName,
                heightMm: panelData?.height,
                widthMm: panelData?.width,
                thickness: panelData?.thickness,
                color: blockPickerColor,
                userId: userId ? userId : searchParams.get('userId')
            }
        }
        doPostApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setLoader(false);
                    resetForm({})
                    let newObj = {
                        name: panelData?.panelName,
                        type: 'panel',
                        title: `${formatNumber(Number((res?.result?.height?.ft)))}'${formatNumber(Number((res?.result?.height?.inch)))}”x${formatNumber(Number((res?.result?.width?.ft)))}'${formatNumber(Number((res?.result?.width?.inch)))}”`,
                        height: formatNumber(((Number(res?.result?.height?.ft) * 12) + Number(res?.result?.height?.inch)) * inchValue),
                        width: formatNumber(((Number(res?.result?.width?.ft) * 12) + Number(res?.result?.width?.inch)) * inchValue),
                        fill: blockPickerColor,
                        panelHeightNew: res?.result?.heightMm,
                        panelWidthNew: res?.result?.widthMm
                    }
                    let arr = [...customPanelArr]
                    arr.unshift(newObj)
                    setCustomPanelArr(arr);
                    handleClosePanelModal();
                    setBlockPickerColor(blockPickerColor)
                    getUserPanel()
                    dispatch(setNotificationOpen({ message: "Success", subText: `Panel created successfully`, alertType: "success", borderClass: "success" }));
                }
            })
            .catch((err: any) => {
                setLoader(false);
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while adding panel`, alertType: "error", borderClass: "error" }));
                console.error(err, "Error adding panel");
            })
    }

    // edit panel api call 
    const editPanel = (panelData: any, resetForm: any) => {
        setLoader(true);
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/panels/${editPanelData.id}`,
            bodyData: {
                name: panelData?.panelName,
                heightMm: panelData?.height,
                widthMm: panelData?.width,
                thickness: panelData?.thickness,
                color: blockPickerColor,
                userId: searchParams.get('userId')
            }
        }
        doPutApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setLoader(false);
                    resetForm({})
                    getUserPanel()
                    handleClosePanelModal();
                    setEditPanelData()
                    setBlockPickerColor("#013A7D")
                    dispatch(setNotificationOpen({ message: "Success", subText: `Panel edited successfully`, alertType: "success", borderClass: "success" }));
                }
            })
            .catch((err: any) => {
                setLoader(false);
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while editing panel`, alertType: "error", borderClass: "error" }));
                console.error(err, "Error editing panel");
            })
    }

    // delete panel api call 
    const deletePanel = (panelData: any) => {
        let userId = getLocalStorageData('userId');
        if (!isActionable(panelData)) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Unable to delete, panel is in use`, alertType: "error", borderClass: "error" }));
            return;
        }
        setLoader(true);
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/panels?from=solarnets-editor`,
            bodyData: {
                "panelIds": [
                    panelData?.id
                ],
                userId: userId ?? '',
            }
        }
        doDeleteApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setLoader(false);
                    getUserPanel()
                    dispatch(setNotificationOpen({ message: "Success", subText: `Panel deleted successfully`, alertType: "success", borderClass: "success" }));
                } else {
                    setLoader(false);
                }
            })
            .catch(err => {
                setLoader(false);
                dispatch(setNotificationOpen({ message: "Failed", subText: `Something went wrong while deleting panel`, alertType: "error", borderClass: "error" }));
                console.error(err, "Error deleting panel");
            })
    }

    const listenKeyBoard = (ev: any) => {
        ev.stopPropagation();
        const activeObject = editorMemo.getActiveObject();
        if (activeObject && activeObject?.objectType === 'panel') {
            ev.preventDefault();
            let setObj: any = {};
            switch (ev.keyCode) {
                case 37:  //ArrowLeft
                    setObj.left = activeObject?.left - 2;
                    break;
                case 38: //ArrowUp
                    setObj.top = activeObject?.top - 2;
                    break;
                case 39: //ArrowRight
                    setObj.left = activeObject?.left + 2;
                    break;
                case 40: //ArrowDown
                    setObj.top = activeObject?.top + 2;
                    break;
                default:
                    console.log(`Default Case Key: ${ev.keyCode}`);
                    break;
            }
            if (Object.keys(setObj)?.length > 0) {
                activeObject.set(setObj);
                editorMemo.renderAll();
            }
        }

    }

    useEffect(() => {
        console.log(threePointStart, 'threePointStart ##');
    }, [threePointStart]);

    const checkForThreePointStart = (data: boolean) => {
        if (data) {
            dispatch(setNotificationOpen({ message: "Waring!", subText: `Please complete the current process first`, alertType: "error", borderClass: "error" }));
        }
    }


    const onAddSupport = async (data: any) => {
        let selectedData = editorMemo.getObjects()?.find((data: any) => { return data.objectType === 'net' })
        const points = editorMemo.getObjects().filter((obj: any) => { return (obj.objectType === 'point' && obj.id.includes(selectedData?.id)) })
        if (selectedData && selectedData?.type === 'polygon' && points?.length > 0) {
            if (data?.action === 'addSupport') {
                if (points?.length > 0) {
                    points.forEach((point: any) => {
                        let supportObj = { ...support }
                        supportObj.lockMovementX = true // Lock horizontal movement
                        supportObj.lockMovementY = true // Lock vertical movement
                        supportObj.left = point.left
                        supportObj.top = point.top
                        supportObj.fill = data?.type === 'cornerSupport' ? '#25be4b' : '#005AFF'
                        supportObj.name = data.name
                        supportObj.id = `${data?.type.toLowerCase()}-${selectedData.id}`
                        supportObj.originX = 'center'
                        supportObj.originY = 'center'
                        supportObj.centeredRotation = true
                        const rectangle = new fabric.Rect(supportObj);

                        editorMemo.add(rectangle);
                        editorMemo.remove(point);
                    });
                }
                editorMemo.discardActiveObject();
                editorMemo.requestRenderAll();
                setAddCornerSupports(false);
            }
            else if (data?.action === 'movePoints') {
                updatePoints(true);
                setThreePointStart(true);
                const objArr = editorMemo.getObjects()
                let netObj = objArr.find((data: any) => { return data.objectType === 'net' })
                if (netObj) {
                    let obj = {
                        netColour: netObj.netColour,
                        type: netObj.name === 'ballnet' ? 'ballnet' : netObj.name === 'catchernet' ? 'catchernet' : 'solarnet',
                        id: netObj.id,
                    }
                    setPolyData(obj);
                    editorMemo.remove(netObj);
                }
                const netNameObj = objArr.find((data: any) => { return data.objectType === 'netname' })
                if (netNameObj) {
                    setNameText(netNameObj.text)
                    editorMemo.remove(netNameObj);
                }
                const netValueObj = objArr.find((data: any) => { return data.objectType === 'netValue' })
                if (netValueObj) {
                    editorMemo.remove(netValueObj);
                }
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Please drag & drop to add support`, alertType: "error", borderClass: "error" }));
            }
        } else if (threePointStart && polyData?.id?.length > 0) {
            // create polygon with id 
            setThreePointStart(false);
            createPolygon('addSupport');
            updatePoints(false);
        }
        else {
            if (selectedData && selectedData?.type === 'polygon') {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Please drag & drop to add support`, alertType: "error", borderClass: "error" }));
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Please add net first`, alertType: "error", borderClass: "error" }));

            }
        }
    }


    // Generate a name for a point
    const generatePointName = (index: number) => {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return alphabet[index]
    };

    // Calculate the area of a polygon
    const calculatePolygonArea = (polygon: any) => {
        const points: any = polygon.get("points");
        const n = points.length;
        let area = 0;

        for (let i = 0; i < n; i++) {
            const j = (i + 1) % n;
            area += formatNumber(((points[i].x * pxValue) / 12) * ((points[j].y) * pxValue) / 12);
            area -= formatNumber(((points[j].x * pxValue) / 12) * ((points[i].y * pxValue) / 12));
        }

        area = Math.abs(area) / 2;
        return area;
    };

    // Calculate the side lengths of a polygon
    const calculatePolygonSideLengths = (polygon: any, type?: string) => {
        const points: any = polygon.get("points");
        const sideLengths = [];
        for (let i = 0; i < points.length; i++) {
            const currentPoint = points[i];
            const nextPoint = points[(i + 1) % points.length];
            const sideLength = Math.hypot(nextPoint.x - currentPoint.x, nextPoint.y - currentPoint.y);
            let obj = {
                name: `${points[i]?.name}${points[(i + 1)]?.name ? points[(i + 1)]?.name : points[0]?.name}`,
                length: formatNumber((sideLength * pxValue) / 12)
            }
            if (type && type === 'view') {
                obj.length = ((sideLength * pxValue) / 12).toFixed(1)
            }
            sideLengths.push(obj);
        }
        return sideLengths;
    };

    const getRectSideLength = (obj: any) => {
        const sides = [
            {
                name: 'A',
                length: `${formatNumber(obj.height)}`
            },
            {
                name: 'B',
                length: `${formatNumber(obj.width)}`
            },
            {
                name: 'C',
                length: `${formatNumber(obj.height)}`
            },
            {
                name: 'D',
                length: `${formatNumber(obj.width)}`
            },
        ]

        return sides;

    }


    // Calculate the height of the polygon
    const calculateHeight = (polygon: any) => {
        const points = polygon.get("points");
        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        points.forEach((point: any) => {
            minY = Math.min(minY, point.y);
            maxY = Math.max(maxY, point.y);
        });
        return maxY - minY;
    };

    // Calculate the width of the polygon
    const calculateWidth = (polygon: any) => {
        const points = polygon.get("points");
        let minX = Number.POSITIVE_INFINITY;
        let maxX = Number.NEGATIVE_INFINITY;
        points.forEach((point: any) => {
            minX = Math.min(minX, point.x);
            maxX = Math.max(maxX, point.x);
        });
        return maxX - minX;
    };

    useEffect(() => {
        if (editor && drawingMode && polyData?.id?.length > 0 && !movePoint) {
            editorMemo.on('mouse:up', function (event: any) {
                const pointer = editorMemo.getPointer(event.e);
                let pointArr = [...points]
                if (startingPoint && isNearStartingPoint(pointer)) {
                    // Create polygon
                    setDrawingMode(false);
                    setStartingPoint(null);
                    createPolygon();
                } else {
                    const nearestCorner: any = findNearestCornerToPoint(pointer);
                    const allPoints = editorMemo.getObjects().filter((obj: fabric.Circle) => { return obj.type === 'circle' })
                    const index = allPoints.findIndex((point: fabric.Circle) => point.left === nearestCorner.x && point.top === nearestCorner.y)
                    if (index > -1) {
                        dispatch(setNotificationOpen({ message: "Failed", subText: `Point already added.`, alertType: "error", borderClass: "error" }));
                        return;
                    }
                    let obj = {
                        left: nearestCorner.original.x,
                        top: nearestCorner.original.y,
                        radius: 4, // Increase the point size here
                        fill: 'black',
                        selectable: false,
                        hasControls: false,
                        objectType: 'point',
                        id: `point-${polyData?.id}-${pointArr.length}`,
                        originX: 'center',
                        originY: 'center',
                    }
                    const point = new fabric.Circle(obj);
                    if (!startingPoint) {
                        point.set({ fill: 'red' })
                    }
                    editorMemo.add(point);
                    let original = { ...obj }
                    original.left = nearestCorner.original.x;
                    original.top = nearestCorner.original.y;
                    pointArr.push(original);
                    setPoints(pointArr);
                    if (!startingPoint) {
                        setStartingPoint(nearestCorner);
                    }
                }

            });

            return () => {
                editorMemo.off('mouse:up');
            }
        }

    }, [editor, loadJson, drawingMode, polyData, startingPoint, movePoint])


    const createPolygon = async (type?: string) => {
        let pointsArr: any[] = []

        editorMemo.forEachObject((targ: any) => {
            if (targ.objectType === 'point' && targ.id?.includes(polyData?.id)) {
                if (type === 'addSupport') {
                    pointsArr.push(targ);
                }
                else {
                    const index = points.findIndex((point: { id: string }) => point.id === targ.id);
                    if (index > -1) {
                        pointsArr.push(points[index]);
                    }
                }
            }
        })

        const pointArr = await removeDuplicatesByLeftTop(pointsArr)

        if (pointArr.length < 3) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `You need at least 3 points to create a polygon.`, alertType: "error", borderClass: "error" }));
            return;
        }
        let object = { ...polygonObj }
        object.id = polyData.id
        object.netId = polyData.type === 'solarnet' ? solarnetId : polyData.type === 'ballnet' ? ballnetId : catcherNetId
        object.netColour = polyData?.colour ? polyData.colour === 'white' ? '#ffe6e6' : '#867979' : polyData?.netColour
        object.name = polyData.type === 'ballnet' ? 'ballnet' : polyData.type === 'catchernet' ? 'catchernet' : 'golfnet'
        let polygon = new fabric.Polygon(pointArr.map((point: any, index: number) => {
            return {
                x: point.left,
                y: point.top,
                name: generatePointName(index)
            };
        }), object);
        editorMemo.add(polygon);

        const maxHeight = calculateHeight(polygon);
        const maxWidth = calculateWidth(polygon);

        let netObj = {
            height: formatNumber(maxHeight),
            width: formatNumber(maxWidth),
            id: polyData.id
        }

        let positionObj = {
            left: formatNumber(polygon.left || 0),
            top: formatNumber(polygon.top || 0)
        }

        await addAdditionalData(netObj, positionObj)

        if (type === 'addSupport') {
            let obj = { type: 'threePointSupport', action: 'addSupport', name: 'Three Point Support' }
            onAddSupport(obj);
        }


        let obj: any = {
            height: formatNumber((maxHeight * pxValue) / 12),
            width: formatNumber((maxWidth * pxValue) / 12),
            id: polyData.id,
            sectionLeft: (Number(polygon.left) - 50),
            sectionTop: (Number(polygon.top) - 50),
            sectionHeight: maxHeight + 150,
            sectionWidth: maxWidth + 150
        }
        // obj.area = formatNumber(area);
        obj.area = formatNumber(obj.height * obj.width);
        obj.itemName = nameText?.length > 0 && nameText != 'Name' ? nameText : 'Name';
        setNetObj(obj);
        setPoints([]);
        setPolyData({});
        setThreePointStart(false);
        setNameText('');

    }

    const removeDuplicatesByLeftTop = (pointsArr: any) => {
        const uniqueArray = pointsArr.reduce((myArr: any, currentObj: any) => {
            // Check if there's already an object with the same left and top values
            const exists = myArr.some((obj: any) => obj.left === currentObj.left && obj.top === currentObj.top);

            // If not, add the current object to the accumulator
            if (!exists) {
                myArr.push(currentObj);
            }

            return myArr;
        }, []);

        return uniqueArray;
    }


    // Check if the pointer is near the starting point
    const isNearStartingPoint = (pointer: any) => {
        const distanceThreshold = 20; // Adjust the threshold as needed
        return (
            Math.abs(pointer.x - startingPoint.x) <= distanceThreshold &&
            Math.abs(pointer.y - startingPoint.y) <= distanceThreshold
        );
    };

    const clickAddNet = (data: any) => {
        const jsonObj = editorMemo.toJSON();
        const panelArr = jsonObj?.objects.filter((data: any) => { return data.objectType === 'panel' })
        const netItem = jsonObj?.objects.filter((data: any) => { return data.objectType === 'net' })
        if (netItem?.length > 0) {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please save or cancel the current net design first`, alertType: "error", borderClass: "error" }));
            return
        }
        if (panelArr?.length > 0) {
            if (data?.mode === 'free') {
                let obj = {
                    colour: data.colour,
                    type: data.type,
                    id: uuidv4(),
                    title: data.title
                }
                editorMemo.defaultCursor = 'crosshair';
                setPolyData(obj);
                setDrawingMode(true);
            }
            else {
                onAddNet(data)
            }
        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please add panels first`, alertType: "error", borderClass: "error" }));
        }
    }

    const closeDrawingMode = () => {
        setDrawingMode(false);
        setPolyData({});
        setPoints([]);
        setStartingPoint(null);
        setMovePoint(false);
        editorMemo.defaultCursor = 'default';
        editorMemo.renderAll();
    }

    const lockCanvasEditor = () => {
        editorMemo.selection = false;
        editorMemo.forEachObject((obj: any) => {
            obj.selectable = false;
            obj.evented = false;
            if (obj?.type === 'polygon') {
                showSideLengths(obj);
                showDistances(obj);
            }
        });
        editorMemo.renderAll();
    }


    const showSideLengths = (polygon: any) => {

        const sideLengths = calculatePolygonSideLengths(polygon, 'view');
        const points = polygon.get('points');

        for (let i = 0; i < points.length; i++) {
            const startPoint = points[i];
            const endPoint = points[(i + 1) % points.length];

            const sideLength = sideLengths[i];

            let options = {
                left: (startPoint.x + endPoint.x) / 2,
                top: ((startPoint.y + endPoint.y) / 2) + 5,
                fill: 'black',
                fontSize: 14,
                selectable: false,
                originX: 'center',
                originY: 'center',
                objectType: 'temp',
                textBackgroundColor: '#EAEDED'
            }
            const text = new fabric.Text(`${sideLength?.name}: ${sideLength?.length}`, options);

            editorMemo.add(text);
        }

    };

    const unlockCanvasEditor = () => {
        setLockCanvas(false);
        editorMemo.selection = true;
        editorMemo.forEachObject((obj: any) => {
            obj.selectable = true;
            obj.evented = true;
            if (obj?.objectType === 'temp') {
                editorMemo.remove(obj);
            }
        });
        editorMemo.renderAll();
    }

    const lockOrUnlockCanvas = () => {
        if (threePointStart) {
            checkForThreePointStart(threePointStart);
            return;
        } else if (lockCanvas) {
            setLockCanvas(false);
            unlockCanvasEditor()
        }
        else {
            setLockCanvas(true);
            lockCanvasEditor()
        }

    }

    const isOnSameXAxis = (point: fabric.Point, referencePoints: fabric.Point[]) => {
        return referencePoints.some(refPoint =>
            Math.abs(refPoint.x - point.x) <= 2
        );
    };

    const isOnSameYAxis = (point: fabric.Point, referencePoints: fabric.Point[]) => {
        return referencePoints.some(refPoint =>
            Math.abs(refPoint.y - point.y) <= 2
        );
    };

    const arePointsClose = (point1: fabric.Point, point2: fabric.Point, threshold: number): boolean => {
        const distance = Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
        return distance <= threshold;
    };

    const removePolygonPointsNearRectangle = (
        polygonPoints: fabric.Point[],
        rectanglePoints: fabric.Point[],
        threshold: number = 2
    ): fabric.Point[] => {
        return polygonPoints.filter(polygonPoint => {
            // Check if the polygon point is not close to any rectangle point
            return !rectanglePoints.some(rectanglePoint => arePointsClose(polygonPoint, rectanglePoint, threshold));
        });
    };


    // Show distances between rectangle corners and two nearest polygon points
    const showDistances = (polygon: any) => {
        const points = polygon.get('points');
        const coordsArr = polygon.getCoords();
        const polygonPoints = removePolygonPointsNearRectangle(points, coordsArr);

        // Calculate which points are on the same x or y line
        const pointOnXAxis = polygonPoints.filter((point: fabric.Point) => isOnSameXAxis(point, coordsArr));
        const pointOnYAxis = polygonPoints.filter((point: fabric.Point) => isOnSameYAxis(point, coordsArr));

        const options = {
            fontSize: 16,
            selectable: false,
            originX: 'center',
            originY: 'center',
            textBackgroundColor: '#F5CBA7',
            objectType: 'temp',
        }

        coordsArr.forEach((coord: any) => {
            let nearestX: any = findNearestPolygonPoint(coord, pointOnXAxis)
            let nearestY: any = findNearestPolygonPoint(coord, pointOnYAxis)

            if (nearestX && Object.keys(nearestX)?.length > 0) {
                const XDistance = pointDistance(coord, nearestX);
                let sortedXDistance = Number(XDistance.toFixed(1));
                if (sortedXDistance > 0.2) {
                    let obj = {
                        left: (coord.x + nearestX.x) / 2 - 15,
                        top: (coord.y + nearestX.y) / 2 - 20,
                        fill: 'red',
                    }
                    const textXdistance = new fabric.Text(`${sortedXDistance}`, { ...options, ...obj });
                    editorMemo.add(textXdistance);
                }
            }

            if (nearestY && Object.keys(nearestY)?.length > 0) {
                const YDistance = pointDistance(coord, nearestY);
                let sortedYDistance = Number(YDistance.toFixed(1));

                if (sortedYDistance > 0.2) {
                    let obj = {
                        left: (coord.x + nearestY.x) / 2 - 15,
                        top: (coord.y + nearestY.y) / 2 - 20,
                        fill: 'blue',
                    }
                    const textYdistance = new fabric.Text(`${sortedYDistance}`, { ...options, ...obj });
                    editorMemo.add(textYdistance);
                }

            }
        })


        // for (let i = 0; i < coordsArr.length; i++) {
        //     const point = coordsArr[i];
        //     const nearestPoints = findNearestPoints(polygonPoints, point, 2);
        //     for (let i = 0; i < nearestPoints.length; i++) {
        //         const nearestCoord = nearestPoints[i];
        //         const distance = pointDistance(point, nearestCoord);

        //         let sortedDistance = Number(distance.toFixed(1))
        //         if (sortedDistance > 0) {
        //             const options = {
        //                 left: (point.x + nearestCoord.x) / 2 - 15,
        //                 top: (point.y + nearestCoord.y) / 2 - 20,
        //                 fill: 'red',
        //                 fontSize: 16,
        //                 selectable: false,
        //                 originX: 'center',
        //                 originY: 'center',
        //                 textBackgroundColor: '#F5CBA7',
        //                 objectType: 'temp',
        //             }
        //             const textDistance = new fabric.Text(`${sortedDistance}`, options);

        //             // editorMemo.add(line, textDistance);
        //             editorMemo.add(textDistance);
        //         }
        //     }
        // }

        const [topLeft, bottomRight] = getBoundingRectangleCorners(polygon);

        const rectObj = {
            fill: 'transparent',
            stroke: 'black',
            strokeWidth: 1,
            strokeDashArray: [5, 5], // Dotted line for the rectangle border
            selectable: false,
            objectType: 'temp',
        }

        // Create a dotted rectangle on the default border
        const rect = new fabric.Polygon(
            [
                { x: topLeft.x, y: topLeft.y },
                { x: bottomRight.x, y: topLeft.y },
                { x: bottomRight.x, y: bottomRight.y },
                { x: topLeft.x, y: bottomRight.y }
            ],
            rectObj
        );

        editorMemo.add(rect);
    }



    // Calculate distance between two points
    function pointDistance(pointA: any, pointB: any) {
        const dx = ((pointB.x - pointA.x) * pxValue) / 12;
        const dy = ((pointB.y - pointA.y) * pxValue) / 12;
        return Math.sqrt(dx * dx + dy * dy);
    }



    function getBoundingRectangleCorners(polygon: any) {
        const coords = polygon.getCoords();
        const xValues = coords.map((coord: any) => coord.x);
        const yValues = coords.map((coord: any) => coord.y);

        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);

        const topLeft = { x: minX, y: minY };
        const bottomRight = { x: maxX, y: maxY };

        return [topLeft, bottomRight];
    }

    function findNearestPoints(pointArray: any, targetPoint: any, numNearest: any) {
        if (pointArray.length === 0 || numNearest <= 0) {
            return [];
        }

        // Calculate the distances from each point in the array to the target point
        const distances = pointArray.map((point: any) => {
            const dx = point.x - targetPoint.x;
            const dy = point.y - targetPoint.y;
            return Math.sqrt(dx * dx + dy * dy);
        });


        // Sort the points based on their distances to the target point
        const sortedPoints = pointArray.map((point: any, index: any) => ({ point, distance: distances[index] })).filter((data: any) => { return formatNumber((data.distance * pxValue) / 12) > 0.2 })
            .sort((a: any, b: any) => a.distance - b.distance);


        // Get the nearest points based on the 'numNearest' parameter
        return sortedPoints.slice(0, numNearest).map((item: any) => item.point);
    }

    const movePoints = () => {
        let value = false;
        if (!movePoint) {
            value = true;
        }
        updatePoints(value);
        setMovePoint(!movePoint)
    }

    const updatePoints = (value: boolean) => {
        let setObj = {
            selectable: value,
        }
        editorMemo.forEachObject((obj: any) => {
            if (obj?.objectType === 'point') {
                obj.set(setObj);
            }
        });
    }

    // Function to find the nearest corner of the rectangles from a given point
    function findNearestCornerToPoint(point: any) {
        let nearestCorner = null;
        let minDistance = Number.MAX_VALUE;

        editorMemo.forEachObject((data: any) => {
            if (data?.objectType === 'panel') {
                const corners = getCorners(data);
                corners.forEach((corner: any) => {
                    const distance = Math.sqrt(
                        Math.pow(corner.x - point.x, 2) + Math.pow(corner.y - point.y, 2)
                    );

                    if (distance < minDistance) {
                        minDistance = distance;
                        nearestCorner = corner;
                    }
                });
            }
        });

        return nearestCorner;
    }

    const findNearestPolygonPoint = (point: fabric.Point, corners: fabric.Point[]) => {
        let nearestCorner = null;
        let minDistance = Number.MAX_VALUE;

        corners.forEach((corner: any) => {
            const distance = Math.sqrt(
                Math.pow(corner.x - point.x, 2) + Math.pow(corner.y - point.y, 2)
            );

            if (distance < minDistance) {
                minDistance = distance;
                nearestCorner = corner;
            }
        });

        return nearestCorner;
    }




    const getCorners = (data: any) => {
        const corner1 = { x: data.left - 4, y: data.top - 4, original: { x: data.left, y: data.top } };
        const corner2 = { x: data.left + data.width, y: data.top - 4, original: { x: data.left + data.width, y: data.top } };
        const corner3 = { x: data.left + data.width, y: data.top + data.height, original: { x: data.left + data.width, y: data.top + data.height } };
        const corner4 = { x: data.left - 4, y: data.top + data.height, original: { x: data.left, y: data.top + data.height } };
        return [corner1, corner2, corner3, corner4];
    }

    // Function to check if a rectangle has any rectangle on its top side
    const hasRectangleOnTop = (targ: any) => {
        let hasTop = false;
        editorMemo.forEachObject((object: any) => {
            if (object !== targ && object.objectType === 'panel') {
                if (object.top + object.height < targ.top &&
                    object.top + object.height > targ.top - 10 &&
                    object.left < targ.left + targ.width &&
                    object.left + object.width > targ.left) {
                    hasTop = true;
                    return;
                }
            }
        });
        return hasTop;
    }

    // Function to check if a rectangle has any rectangle on its below side
    const hasRectangleOnBelow = (targ: any) => {
        let hasBelow = false;
        editorMemo.forEachObject((object: any) => {
            if (object !== targ && object.objectType === 'panel') {
                if (
                    object.top > targ.top + targ.height &&
                    object.top < targ.top + targ.height + 10 &&
                    object.left < targ.left + targ.width &&
                    object.left + object.width > targ.left) {
                    hasBelow = true;
                    return;
                }
            }
        });

        return hasBelow;
    };

    // Function to check if a rectangle has any rectangle on its right side
    const hasRectangleOnRight = (targ: any) => {
        let hasRight = false;
        editorMemo.forEachObject((object: any) => {
            if (object !== targ && object.objectType === 'panel') {
                if (object.left > targ.left &&
                    object.left > targ.left + targ.width &&
                    object.top < targ.top + targ.height &&
                    object.top + object.height > targ.top) {
                    hasRight = true;
                    return;
                }
            }
        });
        return hasRight;
    }

    // Function to check if a rectangle has any rectangle on its left side
    const hasRectangleOnLeft = (targ: any) => {
        let hasLeft = false;
        editorMemo.forEachObject((object: any) => {
            if (object !== targ && object.objectType === 'panel') {
                if (object.left + object.width < targ.left + targ.width &&
                    object.left + object.width < targ.left &&
                    object.top < targ.top + targ.height &&
                    object.top + object.height > targ.top) {
                    hasLeft = true;
                    return;
                }
            }
        });
        return hasLeft;
    }




    // Function to get the top-right corner of a rectangle
    function getTopRightCorner(rect: any) {
        return {
            x: rect.left + rect.width - 4,
            y: rect.top - 4
        };
    }

    // Function to get the top-left corner of a rectangle
    function getTopLeftCorner(rect: any) {
        return {
            x: rect.left - 4,
            y: rect.top - 4
        };
    }

    // Function to get the top-left corner of a rectangle
    function getBottomLeftCorner(rect: any) {
        return {
            x: rect.left - 4,
            y: rect.top + rect.height - 4
        };
    }
    // Function to get the top-left corner of a rectangle
    function getBottomRightCorner(rect: any) {
        return {
            x: rect.left + rect.width - 4,
            y: rect.top + rect.height - 4
        };
    }

    // Funtion to format number if length>8 then 8.8 will result 8, from 8.9 onwards will result 9 else: 8.2 will result 8, from 8.3 onwards till 8.9 will result 9
    const formatNumber = (input: any) => {
        const number = parseFloat(input);
        const numberInFeet = (number * pxValue) / 12
        if (!isNaN(number)) {
            const threshold = numberInFeet < 8 ? 0.2 : 0.8; // we can adjust this threshold as needed
            const roundedNumber = Math.round(number);

            if (number - roundedNumber > threshold) {
                return (roundedNumber + 1);
            } else {
                return roundedNumber;
            }
        } else {
            return input; // Return input if no valid output found
        }
    }


    const saveNetInfo = async () => {
        setBtnLoader(true);
        if (lockCanvas) {
            setBtnLoader(false)
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please Unlock the canvas & save`, alertType: "error", borderClass: "error" }));
        }
        else {
            if (netObj && Object.keys(netObj)?.length > 0 && ((netObj.name && netObj.name !== 'Name') || (netObj.itemName && netObj.itemName !== 'Name'))) {
                // Create item arr structure for api call
                let netArr: any = await createNetItemArr()
                setNetInfo(netArr);
                const netArrBody = await getNetArrBody(netArr)
                await addOrDeleteNetItem(netArrBody)
                reRenderCanvas();
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Please add net name`, alertType: "error", borderClass: "error" }));
                setBtnLoader(false)
            }
        }
    }


    const renderNetObj = (data: any) => {
        reRenderCanvas();
        removeBackgroundText();
        setNetObj(data);
        editorMemo.loadFromJSON(data?.json, editorMemo.renderAll.bind(editorMemo));

    }

    const reRenderCanvas = () => {
        setNetObj({});
        resetData();
        editorMemo.clear();
        addBackgroundText();
        editorMemo.renderAll();
        setBtnLoader(false);
        setCancelPanelModal(false);
    }

    const getNetImage = (data: any) => {
        if (editor) {
            const options = {
                format: 'png',
                left: data.sectionLeft,
                top: data.sectionTop,
                width: data.sectionWidth,
                height: data.sectionHeight,
                multiplier: 2,
                enableRetinaScaling: true
            }

            // Convert the sectionCanvas to an image URL
            lockCanvasEditor();
            const sectionImageURL = editorMemo.toDataURL(options);
            return sectionImageURL;
        }
        else return null

    }


    const createNetItemArr = async () => {
        console.log(netItems, 'netItems ##');
        const jsonObj = editorMemo.toJSON(propertiesToInclude);
        if (jsonObj?.objects?.length > 0) {
            const output = JSON.stringify(jsonObj, null, "\t");
            const netData: any = jsonObj?.objects?.filter((data: any) => { return data?.objectType === 'net' })
            let data = netData[0]
            console.log(data, 'data ##');
            let nameObj = jsonObj?.objects?.find((obj: any) => { return obj.id === `name-${data.id}` });
            const maxHeight = formatNumber(calculateHeight(new fabric.Polygon(data.points, data)));
            const maxWidth = formatNumber(calculateWidth(new fabric.Polygon(data.points, data)));
            const croppingOptions = {
                sectionLeft: formatNumber(Number(data.left) - 50),
                sectionTop: formatNumber(Number(data.top) - 50),
                sectionHeight: Number(maxHeight) + 150,
                sectionWidth: Number(maxWidth) + 150
            }
            let obj: any = {
                id: data.id,
                itemName: nameObj.text,
                height: formatNumber((maxHeight * pxValue) / 12),
                width: formatNumber((maxWidth * pxValue) / 12),
                net: data.netId,
                netColor: data.netColour,
                json: output,
                image: await getNetImage(croppingOptions),
                // area: formatNumber(calculatePolygonArea(new fabric.Polygon(data.points, data))),
                sideLength: await calculatePolygonSideLengths(new fabric.Polygon(data.points, data))
            }
            obj.area = formatNumber(obj.height * obj.width);
            const currentNet = netItems?.find((net: any) => { return net._id === data.netId })
            const cornerSupportMasterData = netItems?.find((item: any) => { return item._id === cornerSId });
            const sideSupportMasterData = netItems?.find((item: any) => { return item._id === sideSId });
            const valleySupportMasterData = netItems?.find((item: any) => { return item._id === valleySId });
            const threePointSupportMasterData = netItems?.find((item: any) => { return item._id === threePointSId });



            console.log(cornerSupportMasterData, 'cornerSupportMasterData ##');

            let totalPrice = (Number(obj?.area) * Number(currentNet?.price))
            if (currentNet?.price) {
                obj.price = totalPrice.toString();

            }
            obj.quantity = netObj?.quantity ? netObj.quantity : 1 // data hardcoded for testing, should be dynamic
            obj.numberOfSides = obj.sideLength?.length ? obj.sideLength?.length : 4
            obj.units = currentNet.units;
            obj.unitPrice = Number(currentNet?.price);
            if (data?.name) {
                obj.name = data.name
            }
            else {
                obj.name = data.netId === solarnetId ? 'golfnet' : data.netId === ballnetId ? 'catchernet' : ''
            }
            // For net support from canvas
            const cornerSupport = jsonObj?.objects?.filter((obj: any) => { return obj?.id === `cornersupport-${data.id}` })
            const sideSupport = jsonObj?.objects?.filter((obj: any) => { return obj?.id === `sidesupport-${data.id}` })
            const valleySupport = jsonObj?.objects?.filter((obj: any) => { return obj?.id === `valleysupport-${data.id}` })
            const threePointSupport = jsonObj?.objects?.filter((obj: any) => { return obj?.id === `threepointsupport-${data.id}` })

            let arr = [...netInfo]
            const index = arr?.findIndex((obj: { id: string }) => obj.id === data.id)
            let supportArr: any = []
            if (index > -1 && arr[index]?.support) {
                supportArr = [...arr[index]?.support]
            }
            const index1 = supportArr.findIndex((data: any) => (data?.id?._id ? data?.id?._id : data.id) === sideSId)
            if (index1 > -1) {
                supportArr.splice(index1, 1) //Doing splice not updating arr[index] item because getting error Cannot assign to read only property 'quantity' of object '#<Object>'
            }
            let objS = {
                id: sideSId,
                quantity: sideSupport?.length,
                price: Number(sideSupportMasterData?.price),
                units: sideSupportMasterData?.units,
                taxable: sideSupportMasterData?.taxable
            }
            supportArr.push(objS)

            const index2 = supportArr.findIndex((data: any) => (data?.id?._id ? data?.id?._id : data.id) === cornerSId)
            if (index2 > -1) {
                supportArr.splice(index2, 1) //Doing splice not updating arr[index] item because getting error Cannot assign to read only property 'quantity' of object '#<Object>'
            }
            let objC = {
                id: cornerSId,
                quantity: cornerSupport?.length,
                price: Number(cornerSupportMasterData?.price),
                units: cornerSupportMasterData?.units,
                taxable: cornerSupportMasterData?.taxable
            }
            supportArr.push(objC)

            const index3 = supportArr.findIndex((data: any) => (data?.id?._id ? data?.id?._id : data.id) === valleySId)
            if (index3 > -1) {
                supportArr.splice(index3, 1) //Doing splice not updating arr[index] item because getting error Cannot assign to read only property 'quantity' of object '#<Object>'
            }
            let objV = {
                id: valleySId,
                quantity: valleySupport?.length,
                price: Number(valleySupportMasterData?.price),
                units: valleySupportMasterData?.units,
                taxable: valleySupportMasterData?.taxable


            }
            supportArr.push(objV)

            const index4 = supportArr.findIndex((data: any) => (data?.id?._id ? data?.id?._id : data.id) === threePointSId)
            if (index4 > -1) {
                supportArr.splice(index4, 1)
            }
            let objTh = {
                id: threePointSId,
                quantity: threePointSupport?.length,
                price: Number(threePointSupportMasterData?.price),
                units: threePointSupportMasterData?.units,
                taxable: threePointSupportMasterData?.taxable

            }
            supportArr.push(objTh);

            obj.support = supportArr;

            let totalQuantity: number = supportArr.reduce((total: number, item: any) => total + item.quantity, 0);

            obj.chain = Math.round(totalQuantity / 2)

            const panels: any = jsonObj?.objects?.filter((data: any) => { return data?.objectType === 'panel' })
            obj.thickness = Number(panels[0].thickness) || 37;
            obj.panelId = panels[0].panelId;


            if (index > -1) {
                const support = obj.support.map((item: { quantity: number }) => ({ ...item, quantity: (item.quantity * obj.quantity) }))
                obj.support = support;
                if (arr[index]?._id) {
                    obj._id = arr[index]._id
                }
                arr[index] = obj;
                let totalQty: number = support.reduce((total: number, item: any) => total + item.quantity, 0);
                obj.chain = Math.round(totalQty / 2)
            }
            else {
                arr.push(obj)
            }
            console.log(arr, " return arr ##")
            return arr;
        }
    }

    const getNetArrBody = async (netInfo: any) => {
        let netArr: any = await Promise.all(netInfo.map(async (data: any) => {
            let obj = { ...data }
            if (data?.image) {
                let image = await getImageFile(data.image, `${data.itemName}.png`);
                await uploadFileData(image).then(async (res: any) => {
                    obj.diagram = {
                        key: res.result[0].key,
                        name: res.result[0].originalname,
                        mimetype: res.result[0].mimetype,
                        location: res.result[0].location,
                        size: res.result[0].size,
                    }
                })
            }
            return obj;
        }))
        return netArr
    }


    const addBackgroundText = () => {
        setShowCancel(false);
        const objArr = editorMemo.getObjects();
        const backgroundText = objArr.find((data: any) => { return data.objectType === 'backgroundtext' })
        if (!backgroundText) {
            const opt = {
                left: editorMemo.width! / 2,
                top: editorMemo.height! / 2,
                originX: 'center',
                originY: 'center',
                fill: '#181818ad',
                fontFamily: 'SansRegular',
                fontSize: 22,
                fontWeight: 'normal',
                selectable: false,
                objectType: 'backgroundtext'
            }
            const textObject = new fabric.Text("Click on module icon to add panels into new array layout", opt);

            editorMemo.add(textObject);
            editorMemo.sendToBack(textObject);
        }
    };

    const removeBackgroundText = () => {
        setShowCancel(true)
        const objArr = editorMemo.getObjects();
        const backgroundText = objArr.find((data: any) => { return data.objectType === 'backgroundtext' })
        if (backgroundText) {
            editorMemo.remove(backgroundText);
        }
    };

    const openDeleteNetModal = (id: string) => {
        setDeleteNetId(id);
        setOpenDeleteNet(true);
    }

    const closeDeleteNetModal = () => {
        setDeleteNetId('');
        setOpenDeleteNet(false);
    }

    const deleteNet = async () => {
        let arr = [...netInfo]
        let filterArr = arr?.filter((netObj: { id: string }) => { return netObj.id !== deleteNetId })
        setNetInfo(filterArr);
        let netArrBody = []
        if (filterArr?.length > 0) {
            netArrBody = await getNetArrBody(filterArr)
        }
        await addOrDeleteNetItem(netArrBody)

    }

    const isActionable = (panelData: any) => {
        if (panelData?.id === 'default') {
            return true;
        }
        else {
            let allObj = editorMemo.getObjects();
            const PanelObjects = allObj?.filter((data: any) => { return data.objectType === 'panel' && data.panelId === panelData.id });
            const filterInfoArr = netInfo?.filter((data: any) => { return data.panelId === panelData.id })
            if (PanelObjects?.length > 0 || filterInfoArr?.length > 0) {
                return false;
            }
            else {
                return true;
            }

        }
    }

    // const checkSupportAdd = () => {
    //     return netInfo.every((item: any) => item?.support && item?.support?.some((support: any) => support?.quantity && support?.quantity > 0))
    // };
    const checkSupportAdd = () => {
        const supportArr = editorMemo.getObjects()?.filter((data: { objectType: string }) => { return data.objectType === 'support' })
        return supportArr?.length > 0
    }

    const checkFunc = () => {
        if (netObj && Object.keys(netObj)?.length > 0 && ((netObj.name && netObj.name !== 'Name') || (netObj.itemName && netObj.itemName !== 'Name'))) {
            let arr = [...netInfo]
            let name = netObj.itemName || netObj.name
            let nameIndex = arr.findIndex((net: { itemName: string }) => net?.itemName?.trim()?.toLowerCase() === name?.trim()?.toLowerCase())
            if (nameIndex > -1 && (netObj?.id !== arr[nameIndex]?.id)) {
                dispatch(setNotificationOpen({ message: "Failed", subText: `Net with same name is already exists`, alertType: "error", borderClass: "error" }));
                setBtnLoader(false)
                return;
            }
            if (checkSupportAdd()) {
                saveNetInfo()
            } else {
                setOpenSupportConfirmModal(true)
            }
        }
        else {
            dispatch(setNotificationOpen({ message: "Failed", subText: `Please add net name`, alertType: "error", borderClass: "error" }));
            setBtnLoader(false)
        }

    }

    /**
     * @author uplvikash
     * @method POST: createMagicLink
     */
    const createMagicLink = (item?: any, resetForm?: any) => {
        setMagicFormBtnLoader(true);
        const userEmail = getLocalStorageData('email'); // this is used when we click resen email from invoice page.
        let data = {
            url: `${process.env.REACT_APP_PUBLIC_apiurl}/users/magic/auth/credentials`,
            bodyData: {
                email: userEmail ? userEmail : item?.email,
                type: magicLinkType === 'QuoteCreate' ? 'quoteCreate' : '',
                path: magicLinkType === 'QuoteCreate' ? `${window?.location?.host}/quote/netsupport` : `${window?.location?.href}`
            }
        }
        doPostApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    // dispatch(setNotificationOpen({ message: "success", subText: res?.message, alertType: "success", borderClass: "success" }));
                    localStorage.setItem("email", res?.result?.email);
                    localStorage.setItem("userId", res?.result?.userId);
                    localStorage.setItem("quoteId", res?.result?.quoteId);
                    setMagicFormBtnLoader(false);
                    setOpenMagicForm(false);
                    resetForm()
                    if (res?.result?.quoteId && res?.result?.userId && magicLinkType === 'QuoteCreate') {
                        saveCanvasData(res?.result?.quoteId.toString(), res?.result?.userId.toString());
                    }
                    if (magicLinkType === 'QuoteCreate' || window.location.pathname === '/quote/invoice') {
                        setMailCheckModalOpen(true);
                    }
                } else {
                    dispatch(setNotificationOpen({ message: "Failed", subText: res?.message, alertType: "error", borderClass: "error" }));
                    setMagicFormBtnLoader(false);
                    setOpenMagicForm(false);
                }
            })
            .catch((err: any) => {
                setMagicFormBtnLoader(false)
                console.error(err);
            })
    }

    /**
        * @author uplvikash
        * @method POST: getUserDetails
        */
    const getUserDetails = async () => {
        console.log("getUserDetails called #");
        const path: string = window.location.href;
        // setLoading(true)
        // let token = searchParams.get('token') || '';
        // console.log("Token", token)
        // if (token) {
        //     localStorage.setItem('token', token);
        // }
        let userId = searchParams.get('userId') || JSON.stringify(localStorage.getItem("userId")) || "";
        let quoteId = searchParams.get('quoteId') || '';
        if (searchParams.get('logedUserId') && searchParams.get('logedUserRole') && searchParams.get('logedUserToken')) {
            localStorage.setItem('logedUserId', searchParams.get('logedUserId') || '');
            localStorage.setItem('logedUserRole', searchParams.get('logedUserRole') || '');
            localStorage.setItem('logedUserToken', searchParams.get('logedUserToken') || '');
        }
        console.log("userId 1", decodeURI(userId))
        if (userId?.length > 0) {
            let url = `${process.env.REACT_APP_PUBLIC_apiurl}/users/${userId}`
            console.log(decodeURIComponent(url), url.replaceAll('"', ''), "url")
            let data = {
                url: url.replaceAll('"', '')
            }
            await userId && doGetApiCall(data)
                .then((res: any) => {
                    if (res.error) {
                        // setLoading(false)
                        // navigate(`/`)
                    }
                    else {
                        dispatch(saveUserDetails(res?.result));
                        localStorage.setItem("user", JSON.stringify(res.result));
                        localStorage.setItem("userId", res.result._id);
                        quoteId = searchParams.get('quoteId') || res.result.quoteId
                        if (searchParams.get('payment')) {
                            if (searchParams.get('offerValue')) {
                                navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&offerValue=${searchParams.get('offerValue')}&salesId=${searchParams.get('salesId')}&tax=${searchParams.get('tax')}&taxrate=${searchParams.get('taxrate')}&total=${searchParams.get('total')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)
                            } else {
                                navigate(`/quote/payment?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&total=${searchParams.get('total')}&format=payment&payment=true&index=${searchParams.get('index') ?? 0}`)
                            }
                        } else if (quoteId && quoteId?.trim()?.length > 0 && searchParams.get('role') === 'admin') {
                            localStorage.setItem("role", 'admin');
                            console.log('admin switch #');
                            navigate(`/quote/editor?userId=${userId}&quoteId=${quoteId}&role=admin`)

                        }
                        // else if (quoteId && quoteId?.trim()?.length > 0) {
                        // navigate(`/quote/editor?userId=${userId}&quoteId=${quoteId}`)
                        // else if (res.result.quoteId) {
                        //     navigate(`/quote/editor?userId=${res.result._id}&quoteId=${res.result.quoteId}`);
                        // }
                        else if (res.result?.quoteId?.length > 0 && path.includes("editor")) {
                            navigate(`/quote/editor?userId=${res.result._id}&quoteId=${quoteId}`)
                        }
                        else {
                            // navigate(`/`)
                        }
                        // setLoading(false)
                    }
                }).catch((error) => {
                    console.log(error);

                })
        }
        else {
            // setLoading(false)
            // navigate(`/`)                 // Remove it for testing purpose 
        }
    }
    const min_quantity = 1;
    const max_quantity = 40;

    const handleQuantityChange = (id: string, type: string, value?: number) => {
        let arr = [...netInfo]
        const index = arr.findIndex((net: { id: string }) => net?.id === id)
        if (index > -1) {
            const oldQty = arr[index].quantity;
            const oldPrice = arr[index].price;
            let qty: any
            if (type === 'type') {
                if (value != null && value <= 40)
                    qty = Math.min(value, max_quantity)
            }
            else {
                qty = type === 'plus' ? Math.min(arr[index].quantity + 1, max_quantity) : Math.max(arr[index].quantity - 1, min_quantity);
            }
            arr[index].quantity = qty
            const support = arr[index].support.map((item: { quantity: number }) => ({ ...item, quantity: (item.quantity / oldQty) * arr[index].quantity }))
            arr[index].support = support;
            let price = (oldPrice / oldQty)
            arr[index].price = (price * arr[index].quantity);

            let totalQty: number = support.reduce((total: number, item: any) => total + item.quantity, 0);
            arr[index].chain = Math.round(totalQty / 2)
        }
        setNetInfo(arr);
    }


    /* Default Panel */

    const handleOpenDefaultPanelModal = () => {
        setIsDefaultPanelModalOpen(true);
    };

    const handleCloseDefaultPanelModal = () => {
        setIsDefaultPanelModalOpen(false);
    };


    //Getting Defualt Panel Data 
    const getDefaultPanelData = (query?: string) => {
        let url = `${process.env.REACT_APP_PUBLIC_apiurl}/panels/default/list?dataPerPage=150`
        if (query) {
            url = `${process.env.REACT_APP_PUBLIC_apiurl}/panels/default/list?string=${query}&dataPerPage=150`
        }
        let data = {
            url: url,
        }
        doGetApiCall(data)
            .then((res: any) => {
                if (!res.error) {
                    setDefaultPanels(res.result)
                } else {
                    setDefaultPanels([])
                }
            })
            .catch((err) => {
                console.error(err, "error");
            });
    }
    //Adding Selected Panels 
    const addSelectDefaultPanels = (panelIds: any) => {
        setLoader(true)
        let userId = getLocalStorageData("userId")
        let token = getLocalStorageData("token");
        if (userId && token) {
            let data = {
                url: `${process.env.REACT_APP_PUBLIC_apiurl}/panels/add/to/list`,
                bodyData: {
                    userId,
                    panelIds
                }
            }
            doPutApiCall(data)
                .then((res: any) => {
                    if (!res.error) {
                        setLoader(false)
                        console.log("Put Request result", res.result)
                        handleCloseDefaultPanelModal();
                        getUserPanel();
                        dispatch(
                            setNotificationOpen({
                                message: "Success",
                                subText: `Panel Added successfully`,
                                alertType: "success",
                                borderClass: "success",
                            }));
                    } else {
                        setLoader(false)
                        dispatch(
                            setNotificationOpen({
                                message: "Failed",
                                subText: `Unable to add default panels`,
                                alertType: "error",
                                borderClass: "error",
                            }))
                    }
                })
                .catch((err) => {
                    console.error(err, "error");
                });
        } else {
            setLoader(false)
            setPanleLibLoginMsg(true);
            handleCloseDefaultPanelModal();
            setSelectedPanels([]);
            // if (!searchParams.get('link') && !searchParams.get('quoteId')) {
            setOpenMagicForm(true);
            // } 

            // dispatch(
            //     setNotificationOpen({
            //         message: "Failed",
            //         subText: `Please login to add panel.`,
            //         alertType: "error",
            //         borderClass: "error",
            //     }))
        }
    }


    const addOrDeleteNetItem = async (itemArr: any) => {
        closeDeleteNetModal()
        let quoteId = searchParams.get('quoteId') || localStorage.getItem("quoteId") || '';
        let userId = searchParams.get('userId') || '';
        localStorage.setItem('quoteId', quoteId);
        localStorage.setItem('userId', userId);
        if (quoteId && quoteId.length > 0) {
            if (quoteData && quoteData.status === 'draft') {
                let body = {
                    item: itemArr,
                    canvasData: itemArr?.length > 0 ? itemArr[0].json : '',
                    diagram: null,
                    quoteName: quoteData.quoteName,
                }
                await updateQuote(body, quoteId).then(async (res: any) => {
                    if (!res?.error) {
                        dispatch(getQuoteData(res.result));
                        dispatch(getDiagramSuccess(res?.result?.items));
                    }
                    else {
                        dispatch(setNotificationOpen({ message: "Failed", subText: `${res.message}`, alertType: "error", borderClass: "error" }));
                    }
                })
            }
            else {
                dispatch(setNotificationOpen({ message: "Failed", subText: "This quote cannot be updated as order is already created", alertType: "error", borderClass: "error" }));

            }
        }
        else {
            console.log("Quote not created")
        }
    }


    return {
        pannelType,
        handlePannelType,
        onAddRectangle,
        getLastObj,
        clickAddNet,
        getScaledData,
        handleClearPanelType,
        handleObjectMoving,
        getSVGfile,
        getJSONdata,
        intersectingCheck,
        onDeleteSelection,
        showDelete,
        onReady,
        editorMemo,
        editor,
        clearCanvas,
        saveCanvasData,
        updateZoom,
        onAddSupport,
        quoteData,
        setQuoteData,
        onQuoteNameChange,
        openEdit,
        handleOpenEdit,
        selected,
        selectionType,
        enableDisableClickThrough,
        spanning,
        resetZoom,
        handleUndo,
        handleRedo,
        netData: netInfo,
        handlePanelModal,
        openPanelModal,
        handleClosePanelModal,
        panelForm,
        customPanelArr,
        blockPickerColor,
        handleColorPicker,
        handleOutclickEdit,
        loader,
        drawingMode,
        threePointStart,
        closeDrawingMode,
        addCornerSupport,
        lockCanvas,
        lockOrUnlockCanvas,
        movePoints,
        movePoint,
        netItems,
        saveNetInfo,
        renderNetObj,
        showAddNet,
        deleteNet,
        cancelNetCreation: reRenderCanvas,
        showCancel,
        netObj,
        handlePanelEdit,
        editPanel,
        deletePanel,
        btnLoader,
        handleSaveCanvasData,
        onConfirmHandler,
        openSupportConfirmModal,
        checkFunc,
        magicLinkForm,
        openMagicForm,
        openMagicModal,
        closeMagicModal,
        magicFormBtnLoader,
        getUserDetails,
        handleQuantityChange,
        handleResetModal,
        showResetModal,
        onConfirmPanelReset,
        handlePanelCancelModal,
        cancelPanelModal,
        polyData,
        handleCancelMagiModal,
        magicConfirmOpen,
        closeMagicConfirmModal,
        mailCheckModalOpen,
        netSupportRedirect,
        handlecloseCheckMailModal,
        createMagicLink,
        checkForThreePointStart,
        isDefaultPanelModalOpen,
        handleOpenDefaultPanelModal,
        handleCloseDefaultPanelModal,
        getDefaultPanelData,
        defaultPanels,
        setDefaultPanels,
        addSelectDefaultPanels,
        selectedPanels,
        setSelectedPanels,
        panleLibLoginMsg,
        handlePanleLibLoginMsg,
        openDeleteNet,
        closeDeleteNetModal,
        openDeleteNetModal
    }
}