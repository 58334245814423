import React from 'react'
import './index.css'
import { sideNavData } from './sideNavData'
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { fetchHelpContent } from '../../redux/helpModalSlice';
import { useDispatch } from 'react-redux';
import help_icon from "../../Images/help_icon.svg";



const SideNav = (props: any) => {
    const dispatch = useDispatch()
    return (
        <div className='sideNav-container !p-0 !border-0 z-20'>
            <div className={`sideNav-item !gap-0 !p-0 !bg-[#f6f6f6] `}>
                {
                    sideNavData?.map((item: any, idx: number) => {
                        return (
                            <div
                                className={`${item?.title === 'Support' ? 'lastItem !py-4' : 'item'}
                            ${props.pannelType === `Solar Panel` ? `roundSolarNet1` : props.pannelType === `Solar Net` ? `roundedPanel roundedSupport` : props.pannelType === `Support` ? `roundSolarNet2` : ``}
                             ${props.pannelType === item?.title ? `bg-[#f6f6f6]` : `bg-[#e4e4e4]`} !py-8 px-3 w-full relative drive-item-${idx + 1} flex flex-col items-start justify-center `}

                                key={idx} onClick={() => {props.handleClick(item?.title); dispatch(fetchHelpContent({keywordItem: "step0_net_editor", type:"HEADER_HELP_CONTENT"}))}}>
                                <p className={`text-red-600 text-sm absolute left-2 top-0 pt-1`}>Step {idx + 1}</p>
                                    <div className={`w-auto h-7 ${item?.title === 'Support' && `!h-9`}   flex items-center justify-center w-16 h-14`}>
                                        <img src={item?.img} alt='img' style={{ width: '100%', height: '100%' }} />
                                    </div>
                                <p className='item-title !text-xs'>{item?.title}</p>
                                <div className="absolute top-1 right-1 justify-start">
                        <img onClick={
                          
                          (e) => {e.stopPropagation();  dispatch(fetchHelpContent({ keywordItem: item?.keyword, type: null}));}

                        } src={help_icon} alt="help_icon" className={`bg-gray-300 hover:bg-gray-500 rounded-full text-sm w-[18px] h-[18px]`} />

                      </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SideNav