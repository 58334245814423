import firstpannel from "../../Images/sidenav/firstpannel.svg";
import secondpannel from "../../Images/sidenav/secondpannel.svg";
import thirdpannel from "../../Images/sidenav/thirdpannel.svg";
import ballnet from "../../Images/sidenav/ballnet.svg";
import solarnet from "../../Images/sidenav/solarnet.svg";
import cornerSupport from "../../Images/sidenav/Rectangle.svg";
import sideSupport from "../../Images/sidenav/plus.svg";
import hexa1 from "../../Images/sidenav/hexa1.svg";
import hexa2 from "../../Images/sidenav/hexa2.svg";
import valleySupport from "../../Images/sidenav/valleysupport.svg";
import threePointSupport from "../../Images/sidenav/threePointSupp.svg"

export const solarPanelData = [
  {
    img: secondpannel,
    title: "Default 1(1676 mm x 1036 mm)",
    type: "panel",
    height: 81.25,
    width: 50,
    name: "Panel 2",
    id: "default",
  },
  {
    img: firstpannel,
    title: "Default 2(1036 mm x 1676 mm)",
    type: "panel",
    height: 50,
    width: 81.25,
    name: "Panel 1",
    id: "default",
  },
];

export const solarnetData = [
  // { img: solarnet, title: "Golf Net - White", type: 'solarnet', colour: 'white', mode:'static' },
  // { img: solarnet, title: "Golf Net - Black", type: 'solarnet', colour: 'black', mode:'static'  },
  // { img: ballnet, title: "Ball Net-White", type: 'ballnet', colour: 'white', mode:'static'  },
  // { img: ballnet, title: "Ball Net-Black", type: 'ballnet', colour: 'black', mode:'static'  },
  {
    img: hexa1,
    title: "Draw Golf Net-White",
    type: "solarnet",
    colour: "white",
    mode: "free",
    keyword: "netadd"
  },
  {
    img: hexa2,
    title: "Draw Golf Net-Black",
    type: "solarnet",
    colour: "black",
    mode: "free",
    keyword: "netadd"
  },
  {
    img: hexa1,
    title: "Draw Hail Blocker-White",
    type: "ballnet",
    colour: "white",
    mode: "free",
    keyword: "netadd"
  },
  {
    img: hexa2,
    title: "Draw Hail Blocker-Black",
    type: "ballnet",
    colour: "black",
    mode: "free",
    keyword: "netadd"
  },
  {
    img: hexa1,
    title: "Baseball Catcher-White",
    type: "catchernet",
    colour: "white",
    mode: "free",
    keyword: "netadd"
  },
  {
    img: hexa2,
    title: "Baseball Catcher-Black",
    type: "catchernet",
    colour: "black",
    mode: "free",
    keyword: "netadd"
  },
];

export const supportData = [
    { img: cornerSupport, title: "Add Corner Support", type: 'cornerSupport', action: 'addSupport', name: 'Corner Support', keyword: 'cornersupport'},
    { img: sideSupport, title: "Side Support", type: 'sideSupport',keyword: 'sidesupport' },
    { img: valleySupport, title: "Valley Support", type: 'valleySupport',keyword: 'valleysupport' },
    { img: threePointSupport, title: "Three Point Support", type: 'threePointSupport', action:'movePoints', name: 'Three Point Support',keyword: 'threepointsupport' }
]

