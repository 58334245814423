// import tokenDecrypt from "../__server__/utils/tokenDecrypt"
import { isExpired } from "react-jwt";

export default async function isValidToken() {
    try {
        const token = await localStorage?.getItem("token")
        console.log(token, "token check >>>")
        if (token) {
            const isTokenExpired = isExpired(token);
            return isTokenExpired
            // true => your token is expired
            // false => your token is not expired
        } else {
            localStorage?.removeItem("user")
            localStorage?.removeItem("userId")
            return false;
        }

    } catch (error) {
        console.log(error, "Error in token check")
        return true;
    }
}