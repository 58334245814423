import "./index.css";
import zoomIn from "../../Images/header/newIcons/zoomin.svg";
import zoomOut from "../../Images/header/newIcons/zoomout.svg";
import prev from "../../Images/header/newIcons/prev.svg";
import next from "../../Images/header/newIcons/next.svg";
import move from "../../Images/header/move.svg";
import edit from "../../Images/header/edit.svg";
import DeleteIcon from "../../Images/header/newIcons/DeleteIcon.svg";

import helpicon from '../../Images/header/help_icon.svg'

import ButtonFieldInput from "../uiComponents/buttonField/Button";
// import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldInput from "../uiComponents/FormFields/TextFieldInput";
import selected from "../../Images/header/move-selected.svg";
import resetZoom from "../../Images/header/zoomRestore.svg";
import { ClickAwayListener } from "@mui/base";
import lock from "../../Images/Icons/lock.svg";
import unlock from "../../Images/Icons/unlock.svg";
import ConfirmationModal from "../uiComponents/ConfirmationModal";
import MagicLinkModal from "../uiComponents/magicLinkModal/MagicLinkModal";
import CancelMagicLinkRequest from "../uiComponents/magicLinkModal/CancelMagicLinkRequest";
import ConfirmMagicLinkModal from "../uiComponents/magicLinkModal/ConfirmMagicLinkModal";
import { Tooltip } from "@mui/material";
import text from "../en_US.json";

const Header = (props: any) => {

    const {
        lockCanvas,
        lockOrUnlockCanvas,
        saveNetInfo,
        saveCanvasData,
        cancelNetCreation,
        showCancel,
        btnLoader,
        checkFunc,
    } = props;

    const handleUndo = () => {
        const ctrlZEvent = new KeyboardEvent("keydown", {
            key: "z",
            code: "KeyZ",
            ctrlKey: true,
            bubbles: true,
        });
        // Dispatch the event on the document object
        document.dispatchEvent(ctrlZEvent);
    };

    const handleRedo = () => {
        const ctrlYEvent = new KeyboardEvent("keydown", {
            key: "y",
            code: "KeyY",
            ctrlKey: true,
            bubbles: true,
            // keyCode: 89,
        });
        // Dispatch the event on the document object
        document.dispatchEvent(ctrlYEvent);
    };

    // const handleZoomIn = () => {

    // }

    // const handleZoomOut = () => {

    // }

    return (
        <div
            className="header-container !z-30"
            style={{ marginTop: props.checkPlatform === "Mobile" ? "60px" : "0px" }}
        >
            {/** header left part */}
            <div className="header-left">
                {/* <div className='header-img'>
                    <img src={home} alt="home" width={26} height={26} />
                </div> */}
                <Tooltip title={text.zoomIn}>
                    <div
                        id="zoom-in"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={(e) => props.handleZoom(e, "zoomin")}
                    >
                        <img src={zoomIn} alt="zoomIn" width={26} height={26} />
                    </div>
                </Tooltip>
                <Tooltip title={text.zoomOut}>
                    <div
                        id="zoom-out"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={(e) => props.handleZoom(e, "zoomout")}
                    >
                        <img src={zoomOut} alt="zoomOut" width={26} height={26} />
                    </div>
                </Tooltip>
                <Tooltip title={text.resetZoom}>
                    <div
                        id="resetzoom"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={props.resetZoom}
                    >
                        <img src={resetZoom} alt="reset" width={26} height={26} />
                    </div>
                </Tooltip>
                <Tooltip title={text.undo}>
                    <div
                        id="undo"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={props.handleUndo}
                    >
                        <img src={prev} alt="prev" width={26} height={26} />
                    </div>
                </Tooltip>
                <Tooltip title={text.redo}>
                    <div
                        id="redo"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={props.handleRedo}
                    >
                        <img src={next} alt="next" width={26} height={26} />
                    </div>
                </Tooltip>

                {/* {props?.selectedHeaderHelpContent && (<Tooltip title={text.span}>
                    <div id='redo' className={props.sidePanelType === '' ? 'disable-img' : 'header-img'}
                        onClick={()=> setOpenModal(true)}>
                        <img src={helpicon} alt="next" width={26} height={26} />
                    </div>
                </Tooltip>)} */}
                


                {props.showDelete && (
                    <div
                        id="delete"
                        className={
                            props.sidePanelType === "" ? "disable-img" : "header-img"
                        }
                        onClick={props.handleDelete}
                    >
                        <img src={DeleteIcon} alt="DeleteIcon" width={26} height={26} />
                        {/* <DeleteIcon /> */}
                    </div>
                )}
                {props.selectionType === "net" && (
                    <>
                        {props.selected ? (
                            <div
                                className={
                                    props.sidePanelType === "" ? "disable-img" : "header-img"
                                }
                                onClick={() => props.enableDisableClickThrough()}
                            >
                                <img src={selected} alt="selected" />
                            </div>
                        ) : (
                            <div
                                className={
                                    props.sidePanelType === "" ? "disable-img" : "header-img"
                                }
                                onClick={() => props.enableDisableClickThrough()}
                            >
                                <img src={move} alt="move" />
                            </div>
                        )}
                    </>
                )}
                {/* {props.drawingMode && (
          <p
            style={{ fontWeight: 500, color: "red" }}
            onClick={() => props.closeDrawingMode()}
          >
            Stop Drawing
          </p>
        )}
        {props.drawingMode && (
          <p
            style={{ fontWeight: 500, color: "red" }}
            onClick={() => props.movePoints()}
          >
            {props.move ? "Stop Moving Points" : "Move Points"}
          </p>
        )} */}
            </div>

            {/** header mid part */}
            <ClickAwayListener onClickAway={props.handleOutclickEdit}>
                <div className="header-mid cursor-pointer">
                    {props.openEdit ? (
                        <TextFieldInput
                            // style={{ boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.05)' }}
                            textnewclass={`bg-transparent rounded-[8px] addaddressInputFieldsGlobal`}
                            placeholder={`Quote Name`}
                            textinputname={`quoteName`}
                            id={`quoteName`}
                            type={`text`}
                            onChange={props.onChange}
                            onBlur={props.handleOpenEdit}
                            value={
                                props.quoteData?.quoteName ? props.quoteData?.quoteName : ""
                            }
                            error={false}
                            errText={""}
                            variant={"standard"}
                        />
                    ) : (
                        <p className="header-mid-text" onClick={props.handleOpenEdit}>
                            {props.quoteData?.quoteName ? props.quoteData?.quoteName : ""}
                        </p>
                    )}
                    <div className="header-mid-img">
                        <img
                            src={edit}
                            alt="edit"
                            onClick={props.handleOpenEdit}
                            width={22}
                            height={22}
                        />
                    </div>
                </div>
            </ClickAwayListener>

            {/** header right part */}
            <div className="header-right">
                <div className="border-[1.5px] border-[#c2c1c1] rounded-3xl">
                    <ButtonFieldInput
                        name="Editor tour"
                        buttonextracls={`!w-15 !text-[#666666] !capitalize !text-sm !bg-[#F5F5F5] hover:bg-[#F5F5F5] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                        handleButton={() => {
                            props.handleDriver();
                        }}
                    //  disabled = {props.netData?.length === 0}
                    />
                </div>
                <div className="border-[1.5px] border-[#c2c1c1] rounded-3xl delete-all-nets">
                    <ButtonFieldInput
                        name="Delete All Nets"
                        buttonextracls={`!w-28 !text-[#666666] !capitalize !text-sm !bg-[#F5F5F5] hover:bg-[#F5F5F5] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                        handleButton={() => {
                            props.handleResetModal();
                        }}
                    //  disabled = {props.netData?.length === 0}
                    />
                </div>
                <div className="border-[1.5px] border-[#c2c1c1] rounded-3xl lock-unlock">
                    <ButtonFieldInput
                        name={lockCanvas ? "Hide Dimensions" : "Display Dimensions"}
                        buttonextracls={`!w-28 !text-[#666666] !capitalize !text-sm !bg-[#F5F5F5] hover:bg-[#F5F5F5] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                        handleButton={lockOrUnlockCanvas}
                        img={lockCanvas ? unlock : lock}
                    />
                </div>
                <div className="save-next-quote">
                    <ButtonFieldInput
                        name={props.showAddNet ? "Save Net" : "Next"}
                        buttonextracls={`!w-28 !text-white !capitalize !text-sm !bg-[#1473E6] hover:bg-[#1473E6] !py-2 !px-4 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                        handleButton={props.showAddNet ? checkFunc : saveCanvasData}
                        loading={props.loader || btnLoader}
                        disabled={props.loader || btnLoader}
                    />
                </div>
                {showCancel && (
                    <div className="border-[1.5px] border-[#c2c1c1] rounded-3xl">
                        <ButtonFieldInput
                            name={"Delete Current Net"}
                            buttonextracls={`!w-28 !text-[#666666] !capitalize !text-sm !bg-[#F5F5F5] hover:bg-[#F5F5F5] !py-2 !px-3 !rounded-3xl !font-bold font-[SansRegular] duration-500 cursor-pointer !w-full`}
                            // handleButton={cancelNetCreation}
                            handleButton={() => props.handlePanelCancelModal()}
                        />
                    </div>
                )}
            </div>
            {props.openSupportConfirmModal && (
                <ConfirmationModal
                    modalText="Support check"
                    open={props.openSupportConfirmModal}
                    handleClose={props.handleSaveCanvasData}
                    handleChange={props.onConfirmHandler}
                    DilogContent={`Proceed without Supports?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}

            {props.showResetModal === true && (
                <ConfirmationModal
                    modalText="Delete All Nets"
                    open={props.showResetModal}
                    handleClose={props.handleResetModal}
                    handleChange={() => props.clearCanvas()}
                    DilogContent={`Are you sure you want to delete all nets?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}
            {props.cancelPanelModal === true && (
                <ConfirmationModal
                    modalText="Delete Current Net"
                    open={props.cancelPanelModal}
                    handleClose={props.handlePanelCancelModal}
                    handleChange={() => cancelNetCreation()}
                    DilogContent={`Are you sure you want to delete current net?`}
                    ConfirmBtn={"Yes"}
                    netData={props.netData}
                    loading={props.loader}
                />
            )}
            {props?.openMagicForm ? (
                <MagicLinkModal
                    open={props.openMagicForm}
                    onClose={props.closeMagicModal}
                    magicLinkForm={props.magicLinkForm}
                    magicFormBtnLoader={props.magicFormBtnLoader}
                    handleCancelMagiModal={props.handleCancelMagiModal}
                    isCancelBtn={true}
                    title={`Congratulations`}
                    description={`You have successfully created net(s), in order to save for future use, kindly enter your email. 
                        We will send you email with account details. if you do not want to save  in your account you can cancel this request.`}
                />
            ) : null}
            {props.mailCheckModalOpen ? (
                <ConfirmMagicLinkModal
                    isClose={false}
                    open={props.mailCheckModalOpen}
                    netSupportRedirect={props.netSupportRedirect}
                />
            ) : null}
            {props?.magicConfirmOpen ? (
                <CancelMagicLinkRequest
                    open={props.magicConfirmOpen}
                    handleClose={props.closeMagicConfirmModal}
                    modalText={`Remove Net Info`}
                    DilogContent={`Are you sure you want to cancel. On canceling net information will be deleted!`}
                    ConfirmBtn={`Yes`}
                />
            ) : null}
            {props.editorMemo?._objects?.length !== 0 && <div className={`flex justify-center items-center absolute top-[56px] w-full border-t-[2.5px] border-[#047AFF]`}>
                <div className={`text-white  bg-[#047AFF]  min-w-fit min-h-fit rounded-bl rounded-br gap-4 px-6 py-1 z-50 absolute top-0`}>
                    <p className="text-sm text-center">{props.threePointStart ? "Please move the points accordingly" : "Save Each Completed Net Design Before Placing Next Solar Array"}</p>
                </div>
            </div>}
        </div>
    );
};

export default Header;