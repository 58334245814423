import { BrowserRouter, Routes, Route } from "react-router-dom"
import UserModule from "./container/authentication"
import Quote from '../src/container/quote/index'
import AddressModule from '../src/components/addAddress'
import PageNotFound from './common/404/404'
import Videomodal from "./common/uiComponents/VideoModal/Videomodal"
import { useDispatch, useSelector } from "react-redux"
import { fetchHelpContent, handleModal } from "./redux/helpModalSlice"
import { useEffect, useState } from "react"
import isValidToken from "./utils/isValidToken"
import TokenExpiryModal from "./common/uiComponents/TokenExpiryModal"

const Router = () => {
  const { isModalOpen, helpContent, headerHelpContent } = useSelector((state: any) => state?.helpModalReducer)
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)

  //Check user valid or not
  const validUser = async () => {
    try {
      const notValid = await isValidToken() // this function return type boolean, true - token expried/ false - token valid
      if (notValid) {
        console.log("Token invalid >>>")
        setOpenModal(true)
        localStorage.clear()
      } else {
        console.log("Token valid or missing >>>")
      }
    } catch (error) {
      console.log(error, `Error in valid user check`)
    }
  }

  useEffect(() => {
    validUser()
    dispatch(fetchHelpContent({ keywordItem: "step0_net_editor", type: "HEADER_HELP_CONTENT" }))
  }, [])

  return (
    <div onClick={validUser}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Quote />} />

          <Route path='login' element={<UserModule />} />
          {/* <Route path="editor" element={<Editor />} /> */}
          <Route path='quote' element={<Quote />} >
            <Route index element={<Quote />} />
            <Route path='addaddress' element={<AddressModule />} />
            <Route path='addquote' element={<Quote />} />
            <Route path="editor" element={<Quote />} />
            <Route path='netsupport' element={<Quote />} />
            <Route path='addshippingaddr' element={<Quote />} />
            <Route path='applyoffer' element={<Quote />} />
            <Route path='payment' element={<Quote />} />
            <Route path='paymentConfirm' element={<Quote />} />
            <Route path='invoice' element={<Quote />} />
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      {isModalOpen && <Videomodal
        open={isModalOpen}
        onClose={() => {
          dispatch(handleModal(false))
        }}
        videoUrl={helpContent ? helpContent : headerHelpContent}
        setSelectedVideoUrl={helpContent}
        dispatch={dispatch}
      />}
      {openModal && < TokenExpiryModal isModalOpen={openModal} setIsModalOpen={setOpenModal} />}
    </div>
  )
}

export default Router
