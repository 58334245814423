import {CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import styles from "./Index.module.css";

const ButtonFieldInput = (props: any) => {
  return (
    <div className={`${styles.mainBtnClass} ${props.btnCustomCls}`}>
      <Button
        // style={{marginTop:'5px'}}
        variant={props.variant}
        className={`${props.buttonextracls && props.buttonextracls}`}
        onClick={props.handleButton}
        component={props.component}
        startIcon={props.startIcon}
        endIcon={props.endIcon}
        to={props.to}
        type={props.type ? props.type : "submit"}
        data-cy={props.dataCy}
        disabled={
          props.disabled
            ? props.disabled
            : props.loading === true
            ? true
            : false
        }
      >
        {props.loading === true ? (
          <div className={styles.loaderSection}>
            <p className={styles.loaderSectionText}>Please wait</p>
            <CircularProgress size={18} className="" color="inherit" />
          </div>
        ) : (
          <div className={`flex items-center justify-center w-full ${props.buttonText}`}>
            {props.img && <div className="mr-2 flex"><img src={props.img} className={props.btnImg}  alt="img" /></div>}
            <div className={`${props.textextracls}`}>
            {props.name}
            </div>
          </div>
        )}
      </Button>
    </div>
  );
};

export default ButtonFieldInput;