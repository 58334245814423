export const uploadFileData = (file: any) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);

        const reqstValues = {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "",
            },
        };
        fetch(`${process.env.REACT_APP_PUBLIC_apiurl}/uploads`, reqstValues)
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                resolve(result);
            })

            .catch((err) => {
                console.log("Error While uploading file", err);
                reject(err);
            });
    })
}

export const updateQuote = (body: any, quoteId: any) => {
    return new Promise((resolve, reject) => {
        let url = `${process.env.REACT_APP_PUBLIC_apiurl}/quotes/${quoteId}?from=solarnets-edior`
        const reqstValues = {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "",
            },
        };
        fetch(url.replaceAll('"', ''), reqstValues)
            .then((result) => {
                return result.json();
            })
            .then((result) => {
                resolve(result);
            })

            .catch((err) => {
                console.log("Error While updating quote", err);
                reject(err);
            });
    })
}