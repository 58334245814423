import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import selectQuote from "../../Images/Icons/selectQuote.svg";
import ButtonFieldInput from "../../common/uiComponents/buttonField/Button";
import getLocalStorageData from "../../utils/getLocalStorageData";
import { doPutApiCall } from "../../utils/ApiConfig";
import attachmentIcon from "../../Images/Icons/attachment.png";
import downloadIcon from "../../Images/Icons/downloadIcon.png";
import minusIcon from "../../Images/Icons/minusIcon.svg";
import plusIcon from "../../Images/Icons/PlusIcon.svg";
import dArrow from "../../Images/netsupport/dArrow.svg";
import next from "../../Images/commonImg/Rarraow.svg";
import back from "../../Images/commonImg/blackLeft.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { setNotificationOpen } from "../../container/notificationReducer/NotificationReducer";
import {
  getDiagramSuccess,
  getQuoteData,
} from "../../container/quote/quoteReducer/diagramReducer";
import QuoteDetailsModal from "../../common/uiComponents/QuoteDetailsModal";
import Addons from "./Addons";
import help_icon from "../../Images/help_icon.svg";
import { fetchHelpContent } from "../../redux/helpModalSlice";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "1px solid gray"
  },
}));

const NetSupport = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const bottomView: any = useRef(null);

  const getUserDiagrams = useSelector(
    (state: any) => state.diagramReducer?.getUserDiagrams
  ); //  getDiagramSuccess Reducer : user net items data
  const quoteData = useSelector(
    (state: any) => state.diagramReducer?.quoteData
  ); //  getQuoteData Reducer: user Quote details
  const getUserSupports = useSelector(
    (state: any) => state.diagramReducer?.getAllSupportList
  ); //  saveAllSupportList Reducer: All available support list from admin

  const [addonsData, setAddonsData]: any = useState([]); // all addons list added from admin
  const [userQuote, setUserQuote]: any = useState([]); // local state to store quote, will be used

  const [showAttachmentFirst, setShowAttachmentFirst] = useState(false);
  const [showAttachmentSecond, setShowAttachmentSecond] = useState(false);
  const [supportObj, setSupportObj]: any = useState({});
  const [firstAttachmentObj, setFirstAttachmentObj]: any = useState({});
  const [secondAttachmentObj, setSecondAttachmentObj]: any = useState({});
  const [selectedDiagramData, setSelectedDiagramData]: any = useState(null);
  const [totalSupport, setTotalSupport]: any = useState(0);
  const [netIdsArr, setNetIdsArr]: any = useState([]);
  const [netNamesArr, setNetNamesArr]: any = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [totalFirstRoofSupport, setTotalFirstRoofSupport]: any = useState(0);
  const [totalSecondRoofSupport, setTotalSecondRoofSupport]: any = useState(0);
  const [noOfChain, setNoOfChain]: any = useState(0);
  const [selectedSupportRoof, setSelectedSupportRoof]: any = useState([]);
  const [selectedSupportId, setSelectedSupportId] = useState("");
  const [selectedSupportIdx, setSelectedSupportIdx]: any = useState({
    idx: 0,
    name: "",
    minRoofs: 0,
  });
  const [selectedNet, setSelectedNet]: any = useState(null);
  const [supportLoading, setSupportLoading] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState("");

  const [addonsQuantity, setAddonsQuantity] = useState(0);
  const [addonsSelectedId, setAddonsSelectedId] = useState("");
  const [addonObj, setAddonObj]: any = useState([]); // Users addons data for a quote
  const [isLoading, setIsLoading] = useState(true);
  const [attachmentObj, setAttachmentObj] = useState<{ [key: string]: any }>(
    {}
  );
  const [attachmentObjCount, setAttachmentObjCount] = useState(0);

  let noAttachedSupportArr: any = [];
  let attachedSupportArrOne: any = [];
  let attachedSupportArrTwo: any = [];
  let roofSupportsArr: any = [];
  let masterDataAddons: any = [];

  // call get quote api
  useEffect(() => {
    props.getQuotes();
  }, []);

  // useEffect(() => {
  //     console.log(getUserDiagrams, 'getUserDiagrams ##');
  // }, [getUserDiagrams]);
  // useEffect(() => {
  //     console.log(quoteData, 'quoteData ##');
  // }, [quoteData]);

  useEffect(() => {
    if (getUserDiagrams && getUserDiagrams.length > 0) {
      setUserQuote(getUserDiagrams);
    }
  }, [getUserDiagrams]);

  useEffect(() => {
    setAddonsData(
      getUserSupports
        ?.filter((item: any) => item?.type === "addons")
        ?.map((itm: any) => {
          return itm;
        })
    );
  }, [getUserSupports]);

  getUserSupports?.map((item: any, index: number) => {
    if (item?.type === "roof") {
      attachedSupportArrOne.push(item);
      attachedSupportArrTwo.push(item);
      roofSupportsArr.push(item);
    } else if (item?.type === "support") {
      noAttachedSupportArr.push(item);
    } else if (item?.type === "addons") {
      masterDataAddons.push(item);
    }
  });

  // array filter to get nets

  let userId = getLocalStorageData("userId")
    ? getLocalStorageData("userId")
    : getLocalStorageData("user")?._id
    ? getLocalStorageData("user")?._id
    : searchParams.get("quoteId");
  const nets = getUserSupports?.filter(
    (nets: any) => nets?.type === "net" && nets
  );

  const handleBack = async () => {
    await addSupportToQuotes(
      getUserDiagrams[Number(searchParams.get("index"))]?._id
    );
    if (searchParams.get("index")) {
      navigate(
        `/quote/editor?userId=${userId}&quoteId=${searchParams.get(
          "quoteId"
        )}&index=${searchParams.get("index") ?? 0}`
      );
    } else {
      navigate(
        `/quote/editor?userId=${userId}&quoteId=${searchParams.get(
          "quoteId"
        )}&index=0`
      );
    }
  };

  const handleIncrease = (idx: any, item: any) => {
    setSupportObj({
      ...supportObj,
      [item?._id]: isNaN(supportObj[item?._id]) ? 1 : supportObj[item?._id] + 1,
    });
    if (attachedWithArray.join(",").includes(item?._id)) {
      if (selectedSupportId === item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      } else if (selectedSupportId !== item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      }
      if (
        !!item?._id &&
        !!selectedSupportId &&
        item?._id !== selectedSupportId
      ) {
        addSupportToQuotes(
          selectedDiagramData?._id,
          Number(searchParams.get("index"))
        );
      }
      setSelectedSupportId(item?._id);
      setSelectedSupportIdx({
        idx: item?._id,
        name: item?.name,
        minRoofs: item?.minRoofs,
      });
      let arr: any = [];
      roofSupportsArr?.map((supp: any) => {
        supp?.attachedWith?.filter(
          (roof: any) => roof?._id === item?._id && arr.push(supp)
        );
      });
      // bottomView.current?.scrollIntoView({ behavior: 'smooth' });
      setSelectedSupportRoof(arr);
    } else {
      console.log("it has no roof attachment");
    }
  };
  const handleDecrease = (idx: any, item: any) => {
    setSupportObj({
      ...supportObj,
      [item?._id]: isNaN(supportObj[item?._id])
        ? 0
        : supportObj[item?._id] > 0
        ? supportObj[item?._id] - 1
        : 0,
    });
    if (attachedWithArray.join(",").includes(item?._id)) {
      if (selectedSupportId === item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      } else if (selectedSupportId !== item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      }
      if (
        !!item?._id &&
        !!selectedSupportId &&
        item?._id !== selectedSupportId
      ) {
        addSupportToQuotes(
          selectedDiagramData?._id,
          Number(searchParams.get("index"))
        );
      }
      setSelectedSupportId(item?._id);
      setSelectedSupportIdx({
        idx: item?._id,
        name: item?.name,
        minRoofs: item?.minRoofs,
      });
      let arr: any = [];
      roofSupportsArr?.map((supp: any) => {
        supp?.attachedWith?.filter(
          (roof: any) => roof?._id === item?._id && arr.push(supp)
        );
      });
      // bottomView.current?.scrollIntoView({ behavior: 'smooth' });
      setSelectedSupportRoof(arr);
    } else {
      console.log("it has no roof attachment");
    }
  };

  const openRoofs = (idx: number, item: any) => {
    if (attachedWithArray.join(",").includes(item?._id)) {
      if (selectedSupportId === item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      } else if (selectedSupportId !== item?._id && !showAttachmentFirst) {
        setShowAttachmentFirst(true);
      }
      if (
        !!item?._id &&
        !!selectedSupportId &&
        item?._id !== selectedSupportId
      ) {
        addSupportToQuotes(
          selectedDiagramData?._id,
          Number(searchParams.get("index"))
        );
      }
      setSelectedSupportId(item?._id);
      setSelectedSupportIdx({
        idx: item?._id,
        name: item?.name,
        minRoofs: item?.minRoofs,
      });
      let arr: any = [];
      roofSupportsArr?.map((supp: any) => {
        supp?.attachedWith?.filter(
          (roof: any) => roof?._id === item?._id && arr.push(supp)
        );
      });
      // bottomView.current?.scrollIntoView({ behavior: 'smooth' });
      setSelectedSupportRoof(arr);
    } else {
      console.log("it has no roof attachment");
    }
  };

  const scrollToRoofSupport = (idx: number) => {
    if (idx === 1) {
      setShowAttachmentFirst(true);
      setShowAttachmentSecond(false);
      window.scrollTo(0, 250);
    } else if (idx === 2) {
      setShowAttachmentSecond(true);
      setShowAttachmentFirst(false);
      window.scrollTo(0, 250);
    }
  };

  const handleShowFirstAttachment = (id: any, idx: number, item: any) => {
    bottomView.current?.scrollIntoView({ behavior: "smooth" });
    if (selectedSupportId === id) {
      setShowAttachmentFirst(!showAttachmentFirst);
    } else {
      setShowAttachmentFirst(true);
    }
    if (!!id && !!selectedSupportId && id !== selectedSupportId) {
      addSupportToQuotes(
        selectedDiagramData?._id,
        Number(searchParams.get("index"))
      );
    } else if (id && id === selectedSupportId && showAttachmentFirst) {
      addSupportToQuotes(
        selectedDiagramData?._id,
        Number(searchParams.get("index"))
      );
    }
    setSelectedSupportId(id);
    setSelectedSupportIdx({
      idx: item?._id,
      name: item?.name,
      minRoofs: item?.minRoofs,
    });
    let arr: any = [];
    roofSupportsArr?.map((item: any) => {
      item?.attachedWith?.filter(
        (roof: any) => roof?._id === id && arr.push(item)
      );
    });
    // setShowAttachmentSecond(false)
    // window.scrollTo(0, 250)
    setSelectedSupportRoof(arr);
  };

  const handleShowSecondAttachment = (idx: any) => {
    if (idx === 2) {
      setShowAttachmentSecond(!showAttachmentSecond);
      setShowAttachmentFirst(false);
      window.scrollTo(0, 250);
    } else {
      setShowAttachmentSecond(false);
    }
  };

  useEffect(() => {
    let obj: any = {};
    let roofOneObj = {};
    let roofSecondObj = {};
    let roofAttachmentObj: any = {};
    let suppIndex = 0;
    if (selectedDiagramData?.support?.length > 0) {
      selectedDiagramData?.support?.map((item: any, index: number) => {
        if (item?.attachedWith === selectedSupportId) {
          roofOneObj = { ...roofOneObj, [suppIndex]: item?.quantity };
          let id = item?.id?._id ? item?.id?._id : item?.id;
          roofAttachmentObj[id] = item?.quantity;
          suppIndex++;
        } else {
          let data = noAttachedSupportArr?.find((supp: any) => {
            return supp._id === (item?.id?._id ?? item?.id);
          });
          !item?.attachedWith &&
            (obj = { ...obj, [data?._id]: item?.quantity });
        }
      });
    } else {
      getUserSupports?.map((item: any, index: number) => {
        obj = { ...obj, [item?._id]: 0 };
      });
    }
    setSupportObj(obj);
    setFirstAttachmentObj(roofOneObj);
    setSecondAttachmentObj(roofSecondObj);
    setAttachmentObj(roofAttachmentObj);
  }, [selectedDiagramData, getUserSupports]);

  useEffect(() => {
    let obj: any = {};
    let roofOneObj = {};
    let notAddedRoofArr = {};
    let roofSecondObj = {};
    let suppIndex = 0;
    let notAddedRoofIdn = 0;
    let roofAttachmentObj: any = {};
    if (selectedDiagramData?.support?.length > 0) {
      selectedDiagramData?.support?.map((item: any, index: number) => {
        if (item?.attachedWith === selectedSupportId) {
          roofOneObj = { ...roofOneObj, [suppIndex]: item?.quantity };
          let id = item?.id?._id ? item?.id?._id : item?.id;
          roofAttachmentObj[id] = item?.quantity;
          suppIndex++;
        } else if (item?.attachedWith !== selectedSupportId) {
          notAddedRoofArr = { ...notAddedRoofArr, [notAddedRoofIdn]: 0 };
          notAddedRoofIdn++;
        } else if (!item?.attachedWith) {
          obj = { ...obj, [index]: item?.quantity };
        }
      });
    }
    setFirstAttachmentObj(roofOneObj);
    setAttachmentObj(roofAttachmentObj);
  }, [selectedSupportId, selectedDiagramData]);

  useEffect(() => {
    props.getSupports();
    console.log(props.test, "#");
  }, [props.test]);

  //this section creation first roof supports quantity

  useEffect(() => {
    if (userId) {
      let obj: any = {};
      if (Object.keys(firstAttachmentObj)?.length > 0) {
        obj = firstAttachmentObj;
      } else {
        roofSupportsArr?.map((item: any, index: number) => {
          obj = { ...obj, [index]: 0 };
        });
      }
      setFirstAttachmentObj(obj);
    } else {
      console.log("useId not available");
    }
  }, [firstAttachmentObj, userId]);

  useEffect(() => {
    if (
      attachmentObj &&
      !Object.keys(attachmentObj)?.length &&
      roofSupportsArr?.length > 0
    ) {
      roofSupportsArr?.map((item: any) => {
        attachmentObj[item._id] = 0;
      });

      setAttachmentObj(attachmentObj);
    }
  }, [attachmentObj, roofSupportsArr, userId]);

  const handleFirstRoofIecrease = (idx: any, id: string) => {
    setFirstAttachmentObj({
      ...firstAttachmentObj,
      [idx]: firstAttachmentObj[idx] + 1,
    });
    attachmentObj[id] = attachmentObj[id] ? attachmentObj[id] : 0;
    setAttachmentObj({ ...attachmentObj, [id]: attachmentObj[id] + 1 });
  };
  const handleFirstRoofDecrease = (idx: any, id: string) => {
    setFirstAttachmentObj({
      ...firstAttachmentObj,
      [idx]: firstAttachmentObj[idx] !== 0 ? firstAttachmentObj[idx] - 1 : 0,
    });
    attachmentObj[id] = attachmentObj[id] ? attachmentObj[id] : 0;
    setAttachmentObj({
      ...attachmentObj,
      [id]: attachmentObj[id] !== 0 ? attachmentObj[id] - 1 : 0,
    });
  };

  const handleSecondRoofIecrease = (idx: any) => {
    setSecondAttachmentObj({
      ...secondAttachmentObj,
      [idx]: secondAttachmentObj[idx] + 1,
    });
  };
  const handleSecondRoofDecrease = (idx: any) => {
    setSecondAttachmentObj({
      ...secondAttachmentObj,
      [idx]: secondAttachmentObj[idx] !== 0 ? secondAttachmentObj[idx] - 1 : 0,
    });
  };

  /**
   * @description update quotes with support
   */
  const addSupportToQuotes = async (selectedId: string, idx?: number) => {
    return new Promise(async (resolve, reject) => {
      setSupportLoading(true);
      let selectedNet: any = {};
      let supportArr: any = [];
      let supportMain: any = {};
      let totalAmount: number = 0;
      await noAttachedSupportArr?.map((item: any, idx: number) => {
        if (!item?.attachedWith?.length) {
          supportMain = {
            support: {
              id: item?._id,
              quantity: Number(supportObj[item?._id])
                ? Number(supportObj[item?._id])
                : 0,
              price: Number(item?.price),
              units: item?.units,
              taxable: item?.taxable,
            },
            subTotalSupp:
              Number(item?.price) * Number(supportObj[item?._id]) || 0,
          };
          if (supportMain && Object.keys(supportMain)?.length > 0) {
            supportArr = [...supportArr, supportMain?.support];
            totalAmount = totalAmount + Number(supportMain?.subTotalSupp);
          }
        }
        // ((item?.attachedWith?.length === 0) && (Number(supportObj[idx]) !== 0 || !Number.isNaN(Number(supportObj[idx])))) && (
        //     supportMain = {
        //         support: {
        //             id: item?._id,
        //             quantity: Number(supportObj[idx]),
        //         },
        //         subTotalSupp: (Number(item?.price) * Number(supportObj[idx]))
        //     }
        // )
        // supportArr = [...supportArr, supportMain?.support]
        // totalAmount = totalAmount + Number(supportMain?.subTotalSupp)
      });

      selectedSupportId &&
        (await selectedSupportRoof?.map((supportOne: any, idx: number) => {
          if (attachmentObj && attachmentObj?.hasOwnProperty(supportOne?._id)) {
            supportMain = {
              support: {
                id: supportOne?._id,
                quantity: Number(attachmentObj[supportOne?._id])
                  ? Number(attachmentObj[supportOne?._id])
                  : 0,
                attachedWith: selectedSupportId,
                price: Number(supportOne?.price),
                units: supportOne?.units,
                taxable: supportOne?.taxable,
              },
              subTotalSupp:
                Number(supportOne?.price) *
                Number(Number(attachmentObj[supportOne?._id])),
            };
            if (supportMain && Object.keys(supportMain)?.length > 0) {
              supportArr = [...supportArr, supportMain?.support];
              totalAmount = totalAmount + Number(supportMain?.subTotalSupp);
            }
          }
          // (((Number(firstAttachmentObj[idx]) !== 0) || (!Number.isNaN(Number(firstAttachmentObj[idx]))))) && (
          //     (supportMain = {
          //         support: {
          //             id: supportOne?._id,
          //             quantity: Number(firstAttachmentObj[idx]),
          //             attachedWith: selectedSupportId
          //         },
          //         subTotalSupp: (Number(supportOne?.price) * (Number(Number(firstAttachmentObj[idx]))))
          //     })
          // )
          // supportArr = [...supportArr, supportMain?.support]
          // totalAmount = totalAmount + Number(supportMain?.subTotalSupp)
        }));
      // let netPrice = nets?.filter((item: any) => item?._id === selectedNet && item)

      selectedNet._id = selectedId;
      selectedNet.support = supportArr;
      selectedNet.chain = Number(noOfChain);
      selectedNet.addOns = addonObj;

      //----------- API call Optimization ---------
      // let itemArr = [...userQuote];
      // const index = itemArr.findIndex((obj: any) => obj?._id === selectedNet._id)
      // console.log(index, 'index ##');
      // if (index > -1) {
      //     itemArr[index].support = selectedNet.support
      //     itemArr[index].chain = selectedNet.chain
      //     itemArr[index].addOns = selectedNet.addOns
      // }
      // setUserQuote(itemArr);

      let data = {
        url: `${process.env.REACT_APP_PUBLIC_apiurl}/quotes/${searchParams.get(
          "quoteId"
        )}?itemId=${selectedId}`,

        bodyData: {
          support: supportArr,
          chain: Number(noOfChain),
          addOns: addonObj,
        },
      };
      await doPutApiCall(data)
        .then((res: any) => {
          if (!res.error) {
            Number(searchParams.get("index")) === idx &&
              setSelectedDiagramData(
                res.result.items[Number(searchParams.get("index"))]
              );
            dispatch(
              setNotificationOpen({
                message: "Support added Successfully",
                subText: `${res?.message}`,
                alertType: "success",
                borderClass: "success",
              })
            );
            props.getQuotes();
            setSupportLoading(false);
          } else {
            dispatch(
              setNotificationOpen({
                message: "Failed",
                subText: `${res?.message}`,
                alertType: "error",
                borderClass: "error",
              })
            );
            setSupportLoading(false);
          }
        })
        .catch((err: any) => {
          dispatch(
            setNotificationOpen({
              message: "Failed",
              subText: `${err?.message}`,
              alertType: "error",
              borderClass: "error",
            })
          );
        });
      resolve("Support added to quote");
    });
  };

  const callNextPage = async () => {
    await addSupportToQuotes(selectedDiagramData?._id);
    props.handleQuoteDetailsOpen();
    // .then((data: any) => {
    //     if (data) {
    //         // props.addSupports(Number(searchParams.get('index')) ?? 0)
    //         navigate(`/quote/addshippingaddr?userId=${searchParams.get('userId')}&quoteId=${searchParams.get('quoteId')}&index=${searchParams.get('index') ?? 0}&page=address`);

    //     }
    // }).catch((err: any) => {
    //     console.log(err, "<<-- error in call Next Page func")
    // })
  };

  const navigateToNextPage = () => {
    navigate(
      `/quote/addshippingaddr?userId=${searchParams.get(
        "userId"
      )}&quoteId=${searchParams.get("quoteId")}&index=${
        searchParams.get("index") ?? 0
      }&page=address`
    );
  };

  let netArr: any = [];

  //
  const checkSupportAdd = async () => {
    await Promise.all(
      getUserDiagrams?.map(async (item: any, index: number) => {
        if (item?.chain === 0) {
          console.log("if part checkSupportAdd >>>>$$");
          setSupportLoading(true);
          if (item?.support) {
            let supportCount = 0;
            let itemChain = 0;
            for (let key in item?.support) {
              if (item?.support[key].quantity !== null) {
                supportCount += item?.support[key].quantity;
              }
            }
            if (supportCount > 0) {
              itemChain = Math.round(Number(supportCount) / 2);
              if (item._id && itemChain > 0) {
                let data = {
                  url: `${
                    process.env.REACT_APP_PUBLIC_apiurl
                  }/quotes/${searchParams.get("quoteId")}?itemId=${item._id}`,

                  bodyData: {
                    chain: Number(itemChain),
                  },
                };
                await doPutApiCall(data)
                  .then((res: any) => {
                    if (!res.error) {
                      console.log(res.result, "final quote >>>>>>>> $$");
                      dispatch(
                        setNotificationOpen({
                          message: "Support added Successfully",
                          subText: `${res?.message}`,
                          alertType: "success",
                          borderClass: "success",
                        })
                      );
                      // props.getQuotes()
                      setSupportLoading(false);
                    } else {
                      dispatch(
                        setNotificationOpen({
                          message: "Failed",
                          subText: `${res?.message}`,
                          alertType: "error",
                          borderClass: "error",
                        })
                      );
                      setSupportLoading(false);
                    }
                  })
                  .catch((err: any) => {
                    dispatch(
                      setNotificationOpen({
                        message: "Failed",
                        subText: `${err?.message}`,
                        alertType: "error",
                        borderClass: "error",
                      })
                    );
                  });
              }
            }
          }
        } else if (item?.support?.length > 0) {
          console.log("alreadyAdded");
        } else if (item?._id === netIdsArr[Number(netIdsArr?.length) - 1]) {
          await addSupportToQuotes(item?._id);
        } else {
          netArr = [...netArr, item?.itemName];
          setNetNamesArr(netArr);
        }
      })
    )
      .then(() => {
        // { (Number(netArr?.length) > 0) ? handleOpenConfirmModal() : callNextPage() }
        callNextPage();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (getUserDiagrams?.length > 0 && isLoading && searchParams.get("index")) {
      setSelectedDiagramData(
        getUserDiagrams[Number(searchParams.get("index"))]
      );
      const index = netIdsArr.findIndex(
        (item: any) =>
          item === getUserDiagrams[Number(searchParams.get("index"))]._id
      );
      if (index < 0) {
        setNetIdsArr([
          ...netIdsArr,
          getUserDiagrams[Number(searchParams.get("index"))]?._id,
        ]);
      }
      setIsLoading(false);
    }
    // if (selectedDiagramData) {
    //     setSelectedDiagramData(selectedDiagramData)
    //     setNetIdsArr([...netIdsArr, selectedDiagramData?._id])

    // }
    // else {
    //     setSelectedDiagramData(getUserDiagrams[Number(searchParams.get('index'))])
    //     setNetIdsArr([...netIdsArr, getUserDiagrams[Number(searchParams.get('index'))]?._id])
    // }
  }, [getUserDiagrams, isLoading, netIdsArr, searchParams]);

  useEffect(() => {
    if (supportObj && Object.keys(supportObj)?.length > 0) {
      let total = 0;
      noAttachedSupportArr.map((item: any) => {
        total += supportObj[item?._id] ? supportObj[item?._id] : 0;
      });
      setTotalSupport(total);
    }
  }, [noAttachedSupportArr, supportObj, totalSupport]);

  useEffect(() => {
    // This useEffect is is used to count total number of roof support attachments when changed using inc/dec btn.
    if (supportObj) {
      let total = 0;
      roofSupportsArr?.map((item: any, idx: number) => {
        total += firstAttachmentObj[idx];
        setTotalFirstRoofSupport(isNaN(total) ? 0 : total);
      });
    }
  }, [firstAttachmentObj, totalSupport]);

  useEffect(() => {
    // This useEffect is is used to count total number of roof support attachments when changed using only input field.
    if (Object?.keys(attachmentObj)?.length > 0) {
      let total = 0;
      Object?.values(attachmentObj)?.reduce((acc, curr) => {
        total = acc + curr;
        return total;
      });
      setAttachmentObjCount(total);
    }
  }, [attachmentObj]);

  useEffect(() => {
    if (supportObj) {
      let total = 0;
      attachedSupportArrTwo?.map((item: any, idx: number) => {
        total += secondAttachmentObj[idx];
        setTotalSecondRoofSupport(total);
      });
    }
  }, [secondAttachmentObj, totalSupport]);

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  // set data for chain support
  useEffect(() => {
    if (totalSupport) {
      setNoOfChain(Math.round(Number(totalSupport) / 2));
    } else if (selectedDiagramData?.chain !== noOfChain) {
      setNoOfChain(Number(noOfChain));
    } else if (selectedDiagramData?.chain) {
      setNoOfChain(Number(selectedDiagramData?.chain));
    } else if (selectedDiagramData?.chain === 0) {
      setNoOfChain(0);
    } else {
    }
  }, [selectedDiagramData, totalSupport]);

  const attachedWithArray = roofSupportsArr?.map((roof: any, idx: number) => {
    return roof?.attachedWith?.map((attached: any) => {
      return attached?._id;
    });
  });

  // this is used for handle selected diagram data in route
  const handleRoute = (index: number) => {
    setNoOfChain(0);
    navigate(
      `/quote/netsupport?userId=${searchParams.get(
        "userId"
      )}&quoteId=${searchParams.get("quoteId")}&index=${index ?? 0}`
    );
  };

  useEffect(() => {
    if (selectedDiagramData?.net) {
      setSelectedNet(selectedDiagramData?.net?._id);
    } else {
      setSelectedNet(nets && nets.length > 0 ? nets[0]?._id : "");
    }
  }, [selectedDiagramData]);

  const downloadPdf = (media: any, id: string) => {
    setSelectedSupport(id);
    setDownloadLoader(true);
    try {
      if (media?.key) {
        const url = `${process.env.REACT_APP_PUBLIC_apiurl}/uploads/file/download?key=${media?.key}`;
        const requestValues: object = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        fetch(url, requestValues)
          .then((response) => {
            return response.blob();
          })
          .then((blob) => {
            setDownloadLoader(false);
            const dataUrl = window.URL.createObjectURL(blob);
            // console.log(dataUrl, "dataUrl")
            const a = document.createElement("a");
            a.href = dataUrl;
            a.setAttribute("download", `${media?.name}`);
            // a.download = media.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          })
          .catch((err) => {
            setDownloadLoader(false);
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err, "error in pdf download");
    }
  };

  const selectId = (id: string) => {
    if (addonsSelectedId !== id) {
      setAddonsSelectedId(id);
    } else {
      // setSelectedId('')
    }
  };
  // const [check, setCheck] = useState(false)

  const useHandleIncreaseAddon = (item: any) => {
    setAddonsQuantity(Math.min(addonsQuantity + 1, 999));
    let newArray: any = addonObj;
    const index = newArray.findIndex((data: any) => data.item === item?._id);
    console.log(index, "index ##");
    // const onsData = addonsData?.filter((ons: any) => ons._id === itemId)
    // const nextAddOns = addonObj.map((i: any) => {
    //     if (i.item === itemId) {
    //         return { item: itemId, quantity: Math.min(i.quantity + 1, 999) };
    //     } else {
    //         return i;
    //     }
    // });
    // setAddonObj(nextAddOns);
    // console.log(onsData,'onsData ##');
    const selectedAddons = masterDataAddons?.find(
      (data: any) => data?._id === item?._id
    );
    if (index > -1) {
      newArray[index].quantity = Math.min(newArray[index].quantity + 1, 999);
      console.log(newArray, "useHandleIncreaseAddon newArray ##");
      setAddonObj(newArray);
    } else {
      setAddonObj([
        ...addonObj,
        {
          item: item?._id,
          quantity: 1,
          price: Number(selectedAddons?.price),
          units: selectedAddons?.units,
          taxable: selectedAddons?.taxable,
        },
      ]);
    }
  };

  const useHandleDecreaseAddon = (item: any) => {
    setAddonsQuantity(Math.max(addonsQuantity - 1, 0));
    let newArray: any = addonObj;
    const index = newArray.findIndex((data: any) => data.item === item?._id);
    console.log(index, "useHandleDecreaseAddon index ##");
    // const onsData = addonsData?.filter((ons: any) => ons._id === itemId)
    const selectedAddons = masterDataAddons?.find(
      (data: any) => data?._id === item?._id
    );

    if (index > -1) {
      newArray[index].quantity = Math.max(newArray[index].quantity - 1, 0);
      console.log(newArray, "newArray ##");
      setAddonObj(newArray);
      // addonObj[index].quantity = Math.max(addonObj[index].quantity - 1, 0)
    } else {
      setAddonObj([
        ...addonObj,
        {
          item: item?._id,
          quantity: 0,
          price: Number(selectedAddons?.price),
          units: selectedAddons?.units,
          taxable: selectedAddons?.taxable,
        },
      ]);
    }
  };

  const useHandleChenge = (e: any, item: any) => {
    selectId(item?._id);
    setAddonsQuantity(e.target.value);
    // const onsData = addonsData?.filter((ons: any) => ons._id === id)
    const selectedAddons = masterDataAddons?.find(
      (data: any) => data?._id === item?._id
    );
    const index = addonObj.findIndex((data: any) => data.item === item?._id);
    if (index > -1) {
      addonObj[index].quantity = e.target.value;
    } else {
      setAddonObj([
        ...addonObj,
        {
          item: item?._id,
          quantity: e.target.value,
          price: Number(selectedAddons?.price),
          units: selectedAddons?.units,
          taxable: selectedAddons?.taxable,
        },
      ]);
    }
  };

  useEffect(() => {
    const onsUpdtId = quoteData?.addOns;
    let updatedOns: any = [];
    onsUpdtId?.map(async (item: any) => {
      const selectedAddons = await masterDataAddons?.find(
        (data: any) => data?._id === item?._id
      );
      updatedOns.push({
        item: item?.item?._id,
        quantity: Number(item?.quantity),
        price: Number(selectedAddons?.price),
        units: selectedAddons?.units,
        taxable: selectedAddons?.taxable,
      });
      setAddonObj(updatedOns);
      // const index = addonsData.findIndex((itm: any) => itm?._id === item?.item?._id)
    });
  }, [quoteData]);

  const saveQuoteDataToAPI = async () => {
    return new Promise(async (resolve, reject) => {
      setSupportLoading(true);

      if (userQuote?.length > 0) {
        let data = {
          url: `${
            process.env.REACT_APP_PUBLIC_apiurl
          }/quotes/${searchParams.get("quoteId")}`,

          bodyData: {
            item: userQuote,
          },
        };
        await doPutApiCall(data)
          .then((res: any) => {
            if (!res.error) {
              console.log(res.result, "final quote >>>>>>>> $$");
              dispatch(getQuoteData(res.result));
              dispatch(getDiagramSuccess(res?.result?.items));
              dispatch(
                setNotificationOpen({
                  message: "Support added Successfully",
                  subText: `${res?.message}`,
                  alertType: "success",
                  borderClass: "success",
                })
              );
              setSupportLoading(false);
            } else {
              dispatch(
                setNotificationOpen({
                  message: "Failed",
                  subText: `${res?.message}`,
                  alertType: "error",
                  borderClass: "error",
                })
              );
              setSupportLoading(false);
            }
          })
          .catch((err: any) => {
            dispatch(
              setNotificationOpen({
                message: "Failed",
                subText: `${err?.message}`,
                alertType: "error",
                borderClass: "error",
              })
            );
          });
      } else {
        dispatch(
          setNotificationOpen({
            message: "Failed",
            subText: `No design found`,
            alertType: "error",
            borderClass: "error",
          })
        );
      }
      setSupportLoading(false);
      resolve("Quote saved");
    });
  };

  const handleQtyChange = (id: string, value: any) => {
    const newValue = parseInt(value, 10);
    if (newValue <= 999) {
      setSupportObj({
        ...supportObj,
        [id]: Number(newValue) < 1 ? 0 : Number(newValue),
      });
    }
  };

  // useEffect(() => {
  //     console.log('addsupportToQuotes call #');
  //     addSupportToQuotes(getUserDiagrams[Number(searchParams.get('index'))]?._id);
  // }, [])

  return (
    <div className={`h-full w-full`}>
      <div className={`mt-4 flex border-t border-solid border-t-[#E0E0E0]`}>
        <div className={` sticky top-[85px] h-screen w-[15%] bg-[#F5F5F5]`}>
          {getUserDiagrams &&
            getUserDiagrams?.length > 0 &&
            getUserDiagrams?.map((item: any, index: number) => {
              return (
                <>
                  <div
                    key={index}
                    className={`flex cursor-pointer items-center justify-between p-3 text-left text-sm font-medium !text-[#515052] ${
                      styles.ListRoot
                    } 
                                    ${
                                      item?._id === selectedDiagramData?._id &&
                                      `flex items-center gap-2 !bg-[#D9D9D9] p-3 text-left text-sm font-medium !text-[#2C2C2C]`
                                    }`}
                    onClick={() => {
                      handleRoute(index);
                      netIdsArr?.length > 0 &&
                        addSupportToQuotes(
                          netIdsArr[Number(netIdsArr?.length) - 1],
                          index
                        );
                      setNetIdsArr([...netIdsArr, item?._id]);
                      setSelectedDiagramData(item);
                    }}
                  >
                    <div className="flex flex-row items-center gap-2">
                      {item?._id === selectedDiagramData?._id ? (
                        <img
                          src={selectQuote}
                          alt="selectQuote"
                          height={18}
                          width={18}
                        />
                      ) : (
                        <p className="h-[18px] w-[18px]"></p>
                      )}
                      <p className="font-roboto text-base">{item?.itemName}</p>
                    </div>
                    <div onClick={(e:any) => {e.stopPropagation(); dispatch(fetchHelpContent({ keywordItem: "select_net", type: null}))}} className=" absolute right-1 top-1 flex justify-start">
                      <img src={help_icon} alt="help_icon" />
                    </div>
                  </div>
                  {/* {item?._id === selectedDiagramData?._id && <div className={`cursor-pointer bg-[#0e95cb] flex justify-center items-center h-[30px] w-[70px] m-auto rounded-[18px] text-white mb-4`} onClick={() => { addSupportToQuotes(item?._id, index) }}>Save</div>} */}
                </>
              );
            })}
        </div>
        <div className={`z-0 w-full pl-2`}>
          <div className={`sticky top-[80px] z-[10] bg-white px-8 pb-2 pt-6`}>
            <div className={`flex items-center justify-between pb-8`}>
              <h2
                className={`font-[SansRegular] text-xl font-medium text-[#333333]`}
              >
                Net Supports
              </h2>
              <div
                className={`z-[1] flex w-fit flex-row items-center justify-center bg-white text-left ${
                  totalSupport >= selectedDiagramData?.numberOfSupport * 1
                    ? "text-[#00907A]"
                    : "text-[#DE353C]"
                } font-[SansRegular] text-sm`}
              >
                <div className="">
                  Minimum Supports Required:{" "}
                  {selectedDiagramData?.numberOfSupport * 1} for{" "}
                  {selectedDiagramData?.itemName}
                </div>
                {!(totalSupport >= selectedDiagramData?.numberOfSupport * 1) ? (
                  <div
                    className={`sticky top-[108px] z-[1] bg-white pl-1 text-center font-[SansRegular] text-sm text-[#DE353C]`}
                  >
                    *Falling short by:{" "}
                    {Number(selectedDiagramData?.numberOfSupport * 1) -
                      Number(totalSupport)}
                    *
                  </div>
                ) : (
                  <div
                    className={`sticky top-[108px] z-[1] bg-white pl-1 text-center font-[SansRegular] text-sm text-[#00907A]`}
                  >
                    *Exceeding by:{" "}
                    {Number(totalSupport) -
                      Number(selectedDiagramData?.numberOfSupport * 1)}
                    *
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`flex flex-col gap-4 p-8`}>
            {noAttachedSupportArr?.map((item: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`${
                    showAttachmentFirst &&
                    selectedSupportRoof?.length > 0 &&
                    selectedSupportId === item?._id &&
                    `rounded-lg border-[1px] border-[#2F80ED]`
                  }`}
                >
                  <div
                    className={`flex cursor-pointer flex-row items-center justify-between gap-4 bg-white p-3 ${
                      showAttachmentFirst &&
                      selectedSupportRoof?.length > 0 &&
                      selectedSupportId === item?._id
                        ? `border-x border-b-0 border-t border-solid border-[#bbbbbb4d] ${styles.gnExtendBorderRadius}`
                        : `${styles.card} rounded-lg `
                    }`}
                    key={idx}
                  >
                    <div className={`flex flex-row items-center`}>
                      <div
                        className={`flex h-24 w-80 items-center justify-center rounded-md border border-solid border-[#D9D9D9] bg-[#FAFAFA] p-2`}
                      >
                        <img
                          crossOrigin="anonymous"
                          src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.picture?.key}&width=12&height=18&fit=contain`}
                          alt="img"
                          className={styles.contain}
                        />
                      </div>
                      <div>
                        <div className={`flex items-start gap-2`}>
                          <p
                            className={`pl-4 text-left font-[SansRegular] text-base font-semibold text-[#4F4F4F]`}
                          >
                            {item?.name}
                          </p>
                          <img onClick={()=> dispatch(fetchHelpContent({ keywordItem: (item?.name).toLowerCase().replace(/ /g,"_"), type: null}))} src={help_icon} alt="help icon" />
                        </div>
                        <p
                          className={`px-4 text-left font-[SansRegular] text-sm font-normal text-[#BDBDBD]`}
                        >
                          {item?.description}
                        </p>
                      </div>
                    </div>
                    <div className={`flex flex-row justify-end`}>
                      {item?.documentFile?.key && (
                        <div
                          className={`flex items-center pl-2`}
                          onClick={() => {
                            downloadPdf(item?.documentFile, item?._id);
                          }}
                        >
                          <img
                            src={attachmentIcon}
                            height={20}
                            width={20}
                            alt="attchemntIcon"
                          />
                          <p className={`w-32 truncate`}>
                            {item?.documentFile?.name}
                          </p>
                          {downloadLoader && item?._id === selectedSupport && (
                            <div className={`animate-bounce`}>
                              <img
                                src={downloadIcon}
                                height={15}
                                width={15}
                                alt="downloadIcon"
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <div className={`flex flex-col gap-2`}>
                        <div
                          className={`flex items-center justify-between px-4`}
                        >
                          <div>
                            <p
                              className={`font-[SansRegular] text-lg text-[#4F4F4F]`}
                            >
                              ${item?.price}
                            </p>
                          </div>
                          <div
                            className={`ml-4 flex h-[30px] w-[100px] items-center justify-around rounded-3xl border border-solid border-[#e4e4e480] bg-[#ffffff]`}
                          >
                            {
                              <div
                                className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                                onClick={() => handleDecrease(idx, item)}
                              >
                                <img
                                  src={minusIcon}
                                  height={10}
                                  width={10}
                                  alt={`minusIcon`}
                                />
                              </div>
                            }
                            {/* <p className={`text-[#4F4F4F] font-[SansRegular] text-base`}>{supportObj[idx]}</p> */}
                            <input
                              max="999"
                              disabled={supportLoading}
                              value={
                                isNaN(supportObj[item?._id])
                                  ? 0
                                  : supportObj[item?._id]?.toString()
                              }
                              className={`h-[30px] w-10 border-y border-solid border-[#e4e4e480] bg-[#ffffff] text-center text-[#828282] outline-none focus:bg-[#bfd6ff]`}
                              type="number"
                              onChange={(e: any) =>
                                handleQtyChange(item?._id, e.target.value)
                              }
                              onFocus={() => {
                                openRoofs(idx, item);
                              }}
                              onWheel={(e: any) => e.target.blur()}
                            />
                            {
                              <div
                                className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                                onClick={() => handleIncrease(idx, item)}
                              >
                                <img
                                  src={plusIcon}
                                  height={10}
                                  width={10}
                                  alt={`plusIcon`}
                                />
                              </div>
                            }
                          </div>
                          <div className="flex items-start gap-1">
                            <p
                              className={`w-10 pl-2 font-[SansRegular] text-lg text-[#4F4F4F]`}
                            >
                              {Math.round(supportObj[item?._id]) > 1
                                ? "Pcs."
                                : "Pc."}
                            </p>
                            {idx === 0 && <img onClick={() =>  dispatch(fetchHelpContent({ keywordItem: "support_count", type: null}))} src={help_icon} alt="help icon" />}
                          </div>
                        </div>
                        {attachedWithArray.join(",").includes(item?._id) && (
                          <div className={`flex flex-col gap-2`}>
                            <div
                              className={`flex w-full flex-row items-center justify-center gap-6`}
                            >
                              {attachedSupportArrOne?.map(
                                (roof: any, idx: number) => {
                                  let ids = roof?.attachedWith?.filter(
                                    (ids: any, index: number) =>
                                      ids?._id === item?._id
                                  );
                                  let roofQuantity =
                                    selectedDiagramData?.support?.filter(
                                      (supp: any) =>
                                        supp?.attachedWith === item?._id &&
                                        (supp?.id?._id ?? supp?.id) ===
                                          roof?._id
                                    );
                                  return (
                                    item?._id === ids[0]?._id && (
                                      <div
                                        className={`flex flex-col items-center`}
                                        key={idx}
                                      >
                                        <div className="z-[1] flex h-[19px] w-[18px] items-center justify-center rounded-[3px] bg-[#2F80ED] font-roboto text-xs font-medium text-white">
                                          {showAttachmentFirst &&
                                          item?._id === selectedSupportId &&
                                          attachmentObj
                                            ? attachmentObj[roof?._id]
                                            : roofQuantity?.length > 0
                                            ? roofQuantity[0]?.quantity
                                            : 0}
                                        </div>
                                        <div className="-m-2 flex h-[47px] w-[41px] items-center justify-center rounded-[4px] border border-solid border-[#bbbbbb4d]">
                                          <img
                                            crossOrigin="anonymous"
                                            src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${roof?.picture?.key}`}
                                            width={30}
                                            height={30}
                                            alt="img"
                                            className="contain"
                                          />
                                        </div>
                                      </div>
                                    )
                                  );
                                }
                              )}
                              {/* <div className="flex justify-center items-center bg-[#2F80ED] gap-1.5 rounded h-[46px] w-[71px] mt-4">
                                                                <img src={gnSupportsAttachments} alt='gnSupportsAttachments' width={24} height={24} />
                                                                <p className="text-xl text-white font-medium font-roboto">3</p>
                                                            </div> */}
                            </div>
                            <div
                              className={`flex items-center justify-between px-4`}
                              onClick={() =>
                                handleShowFirstAttachment(item?._id, idx, item)
                              }
                            >
                              <p
                                className={`text-left text-base font-medium text-[#2F80ED]`}
                              >
                                Roof Attachments
                              </p>
                              <p
                                className={
                                  showAttachmentFirst &&
                                  item?._id === selectedSupportId
                                    ? `rotate-180`
                                    : ""
                                }
                              >
                                <img
                                  src={dArrow}
                                  height={15}
                                  width={15}
                                  alt={`dArrow`}
                                />
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {showAttachmentFirst &&
                    selectedSupportRoof?.length > 0 &&
                    selectedSupportId === item?._id && (
                      <div
                        ref={bottomView}
                        className={`rounded-b-lg border border-solid border-[#bbbbbb4d]`}
                      >
                        <div
                          className={`border-x border-solid border-[#bbbbbb4d] ${
                            Number(totalFirstRoofSupport) >=
                              supportObj[selectedSupportIdx.idx] *
                                selectedSupportIdx?.minRoofs ||
                            attachmentObjCount >=
                              supportObj[selectedSupportIdx.idx] *
                                selectedSupportIdx?.minRoofs
                              ? "bg-[#50b94d]"
                              : "bg-[#DE353C]"
                          } flex items-center justify-between p-3 text-white`}
                        >
                          <h2
                            className={`font-[SansRegular] text-xl font-medium `}
                          >
                            Roof Attachments
                          </h2>
                          <h2
                            className={`font-[SansRegular] text-sm font-medium`}
                          >
                            Minimum Roof Attachments required:{" "}
                            {supportObj[selectedSupportIdx.idx] *
                              selectedSupportIdx?.minRoofs}{" "}
                            {supportObj[selectedSupportIdx.idx] > 1
                              ? "Pcs."
                              : "Pc."}{" "}
                            for {selectedSupportIdx?.name}
                          </h2>
                        </div>
                        <div className={`flex flex-col`}>
                          {selectedSupportRoof?.map(
                            (item: any, idx: number) => {
                              return (
                                <div className="flex flex-col" key={idx}>
                                  <div
                                    className={`flex cursor-pointer items-center justify-between gap-4 bg-white p-3 `}
                                  >
                                    <div
                                      className={`flex flex-row items-center pl-8`}
                                    >
                                      <LightTooltip title={<div className={`w-[150px] h-[150px] p-2 flex items-center`}>
                                        <img
                                          crossOrigin="anonymous"
                                          src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.picture?.key}`}
                                          width={150}
                                          height={150}
                                          alt="img"
                                          
                                          className="contain"
                                        />
                                      </div>}>
                                        <div
                                          className={`flex items-center justify-center rounded-md border border-solid border-[#D9D9D9] bg-[#FAFAFA] p-2`}
                                        >
                                          <img
                                            crossOrigin="anonymous"
                                            src={`${process.env.REACT_APP_PUBLIC_apiurl}/uploads/?key=${item?.picture?.key}`}
                                            width={30}
                                            height={30}
                                            alt="img"
                                            className="contain"
                                          />
                                          {/* {item?.pic1 ? <img src={item?.pic1} width={180} height={200} alt="img" /> : null} */}
                                          </div>
                                      </LightTooltip>
                                      <div className="flex flex-col">
                                        <p
                                          className={`px-4 text-left font-roboto text-base font-medium text-[#5991D6]`}
                                        >
                                          {item?.name}
                                        </p>
                                        <p
                                          className={`px-4 text-left text-sm font-normal text-[#494949]`}
                                        >
                                          {item?.description}
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className={`flex items-center justify-center px-4`}
                                    >
                                      <div className={``}>
                                        {item?.documentFile?.key && (
                                          <div
                                            className={`flex items-center px-4`}
                                            onClick={() => {
                                              downloadPdf(
                                                item?.documentFile,
                                                item?._id
                                              );
                                            }}
                                          >
                                            <img
                                              src={attachmentIcon}
                                              height={20}
                                              width={20}
                                              alt="attchemntIcon"
                                            />
                                            <p className={`w-28 truncate`}>
                                              {item?.documentFile?.name}
                                            </p>
                                            {downloadLoader &&
                                              item?._id === selectedSupport && (
                                                <div
                                                  className={`animate-bounce`}
                                                >
                                                  <img
                                                    src={downloadIcon}
                                                    height={15}
                                                    width={15}
                                                    alt="downloadIcon"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </div>
                                      <div>
                                        <p
                                          className={`font-roboto text-base font-semibold text-[#01947C]`}
                                        >
                                          ${item?.price}
                                        </p>
                                      </div>
                                      <div
                                        className={`ml-4 flex h-[30px] w-[100px] items-center justify-around rounded-3xl border border-solid border-[#e4e4e480] bg-[#ffffff]`}
                                      >
                                        {
                                          <div
                                            className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                                            onClick={() =>
                                              handleFirstRoofDecrease(
                                                idx,
                                                item?._id
                                              )
                                            }
                                          >
                                            <img
                                              src={minusIcon}
                                              height={10}
                                              width={10}
                                              alt={`minusIcon`}
                                            />
                                          </div>
                                        }
                                        {/* <p className={`text-[#4F4F4F] font-roboto text-base`}>{supportObj[idx]}</p> */}
                                        <input
                                          onWheel={(e: any) => e.target.blur()}
                                          type="number"
                                          id="id"
                                          max="999"
                                          value={
                                            attachmentObj[item?._id]
                                              ? attachmentObj[item?._id]
                                              : 0
                                          }
                                          onChange={(e: any) =>
                                            setAttachmentObj({
                                              ...attachmentObj,
                                              [item?._id]:
                                                Number(e.target.value) < 1
                                                  ? 0
                                                  : Number(e.target.value) > 999
                                                  ? 999
                                                  : Number(e.target.value),
                                            })
                                          }
                                          className={`h-[30px] w-10 border-y border-solid border-[#e4e4e480] bg-[#ffffff] text-center font-roboto text-lg font-normal text-[#4F4F4F] outline-none focus:bg-[#bfd6ff]`}
                                          // onChange={(e: any) => { Number(e.target.value) < 0 ? 0 : Number(e.target.value) > 999 ? 999 : setFirstAttachmentObj({ ...firstAttachmentObj, [idx]: Math.round(Number(e.target.value)) }) }}
                                        />
                                        {
                                          <div
                                            className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                                            onClick={() =>
                                              handleFirstRoofIecrease(
                                                idx,
                                                item?._id
                                              )
                                            }
                                          >
                                            <img
                                              src={plusIcon}
                                              height={10}
                                              width={10}
                                              alt={`plusIcon`}
                                            />
                                          </div>
                                        }
                                      </div>
                                      <p
                                        className={`w-10 pl-2 font-roboto text-base font-medium text-[#333333]`}
                                      >
                                        {attachmentObj[item?._id] > 1
                                          ? "Pcs."
                                          : "Pc."}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="ml-11 mr-7 h-0 border-b border-solid border-b-[#bbbbbb4d]"></p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                </div>
              );
            })}

            <div className={`pb-2`}>
              <div
                className={`flex w-full flex-row items-center justify-between rounded-lg border border-solid border-[#e4e4e480] bg-[#ffffff] p-4`}
              >
                <div
                  className={`z-[1] flex w-full flex-col text-left font-roboto text-white`}
                >
                  <span className={`text-lg font-semibold text-[#4F4F4F]`}>
                    Minimum Tensioner Chain+3 Carabiners
                  </span>
                  <span className={`text-base font-normal text-[#4F4F4F]`}>
                    Required: {Math.round(Number(totalSupport) / 2)} for{" "}
                    {selectedDiagramData?.itemName}
                  </span>
                </div>
                <div className={`flex items-center`}>
                  <div
                    className={`ml-4 flex h-[30px] w-[100px] items-center justify-around rounded-3xl border border-solid border-[#e4e4e480] bg-[#ffffff]`}
                  >
                    {
                      <div
                        className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                        onClick={() => {
                          setNoOfChain(noOfChain > 0 ? noOfChain - 1 : 0);
                        }}
                      >
                        <img
                          src={minusIcon}
                          height={10}
                          width={10}
                          alt={`minusIcon`}
                        />
                      </div>
                    }
                    {/* <p className={`text-[#4F4F4F] font-[SansRegular] text-base`}>{supportObj[idx]}</p> */}
                    <input
                      onWheel={(e: any) => e.target.blur()}
                      type="number"
                      disabled={supportLoading}
                      id="id"
                      max="999"
                      value={noOfChain}
                      className={`h-[30px] w-10 border-y border-solid border-[#e4e4e480] bg-[#ffffff] text-center font-[SansRegular] text-lg text-[#828282] outline-none focus:bg-[#bfd6ff]`}
                      // onChange={(e: any) => { Number(e.target.value) < 0 ? 0 : Number(e.target.value) > 999 ? 999 : setNoOfChain(Number(e.target.value)) }}
                    />
                    {
                      <div
                        className={`flex h-full w-[20px] cursor-pointer items-center justify-center`}
                        onClick={() => {
                          setNoOfChain(noOfChain + 1);
                        }}
                      >
                        <img
                          src={plusIcon}
                          height={10}
                          width={10}
                          alt={`plusIcon`}
                        />
                      </div>
                    }
                  </div>
                  <p
                    className={`ml-2 w-10 font-roboto text-lg font-medium text-white`}
                  >
                    {noOfChain > 1 ? "Sets" : "Set"}
                  </p>
                </div>
              </div>
            </div>

            <div className={`flex items-center justify-between `}>
              {/* <div className={`flex flex-row items-center gap-2`} onClick={onOpenAddon}> */}
              <h2
                className={`font-[SansRegular] text-xl font-medium text-[#333333]`}
              >
                Add-Ons (For entire quote)
              </h2>
              {/* </div> */}
              <></>
            </div>
            <Addons
              addonObj={addonObj}
              selectId={selectId}
              selectedId={addonsSelectedId}
              getAddons={addonsData}
              handleIncreaseAddon={useHandleIncreaseAddon}
              handleDecreaseAddon={useHandleDecreaseAddon}
              onSetAddonsQuantity={useHandleChenge}
            />
          </div>
          <div className={`flex items-center justify-center`}>
            <div
              className={`flex w-[16rem] justify-between py-10 sm:w-[30rem]`}
            >
              <div className={`rounded-3xl border border-[#E0E0E0]`}>
                <ButtonFieldInput
                  type={`submit`}
                  name={`Back`}
                  startIcon={
                    <img src={back} width={22} height={22} alt="arrow" />
                  }
                  handleButton={handleBack}
                  buttonextracls={`!text-black text-base !py-2 !px-6 !font-normal !font-[SansRegular] cursor-pointer`}
                />
              </div>
              <ButtonFieldInput
                name="Next"
                type="submit"
                handleButton={() => checkSupportAdd()}
                // handleButton={() => props.handleQuoteDetailsOpen()}
                endIcon={<img src={next} width={18} height={18} alt="arrow" />}
                buttonextracls={`!text-white text-base !bg-[#2F80ED] !hover:bg-[#2F80ED] !py-2 !px-6 !rounded-3xl font-normal font-[SansRegular] cursor-pointer`}
                loading={supportLoading}
                disabled={supportLoading === true && true}
              />
            </div>
          </div>
          {/* <SupportAddConfirmationModal open={openConfirmModal} onClose={handleCloseConfirmModal} mapData={netNamesArr} /> */}
        </div>
      </div>
      <QuoteDetailsModal
        open={props?.quoteDetailsOpen}
        handleClose={props?.handleQuoteDetailsClose}
        navigateToNextPage={navigateToNextPage}
        supportLoading={supportLoading}
      />
    </div>
  );
};

export default NetSupport;
