export const navigateToGn = (str?: any, userId?: any, quoteId?: any, index?: any, token?: any) => {
    // localStorage.clear();
    console.log(str,userId, quoteId,index, token ,'navigateToGn ##' );
    const gtToken = localStorage.getItem('token');
    if (str && userId && quoteId && token) {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}/${str}?userId=${userId}&quoteId=${quoteId}&token=${token}&index=${index}`;
    }
    else if (str && userId && quoteId) {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}/${str}?userId=${userId}&quoteId=${quoteId}&index=${index}&token=${gtToken}`;
    } else if (str && userId && token) {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}/${str}?userId=${userId}&quoteId=${quoteId}&index=${index}&token=${token}`;
    }
    else if (userId && quoteId) {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}?userId=${userId}&quoteId=${quoteId}&index=${index}&token=${gtToken}`;
    }
    else if(userId){
        return `${process.env.REACT_APP_PUBLIC_GN_URL}?userId=${userId}&token=${gtToken}`;
    }
    else if (str) {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}/${str}`;
    } else {
        return `${process.env.REACT_APP_PUBLIC_GN_URL}`;
    }
}